import React from "react";

import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

import CardBody from "components/Card/CardBody.js";
import Learn from "assets/img/learning.png";
import Sales from "assets/img/sales.png"
import POC from "assets/img/enggsandbox.png"
import Designer from "assets/img/designer.png"


import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import ViewOffering from "./ViewOffering";
import Axios from "axios";
import { Api } from "config/api";
import { header } from "config/api";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  const [value, setValue] = React.useState([]);
  const [poc, setPOC] = React.useState([])

  React.useEffect(()=>{

    Axios.get(Api.getNewLearning, {headers:header})
      .then((data)=>{
       console.log(data.data.learnings)
        setValue(data.data.learnings)
      }).catch(err=>{
        console.log(err);
      })


      Axios.get(Api.getUserProfile, {headers:header})
      .then((data)=>{
        setPOC(data)
      }).catch(err=>{
        console.log(err);
      })
    
  },[]);

  return (
    <div>
      <GridContainer>
        <GridItem>
         <div style={{color:"#067193"}}>
          <h4 style={{marginTop:"10%", fontWeight:"bold"}}>Criterion SDCloud Platform</h4><br />
          <p >Develop, validate and learn SDN/NFV solutions in a matter of minutes</p>
          </div>
        </GridItem>
      </GridContainer>
      {/* <GridContainer>
        <GridItem  >
          <Card>
            
            <CardBody>
              <GridContainer style={{marginLeft:"10%"}}>
                <GridItem xs={12} sm={12} md={4}>
                 <p style={{color:"#067193",fontWeight:"bold"}}>Active Learning Labs - 0 </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                 <p style={{color:"#067193",fontWeight:"bold"}}>Active Sales Demos - 0</p>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                 <p style={{color:"#067193",fontWeight:"bold"}}>Active POC Libraries - 0</p>
                </GridItem>
  
                
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer> */}
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card color="info">
            <CardBody>
            <p style={{color:"white",fontWeight:"bolder", fontSize:"16px"}}>Learning Lab Sessions</p>
              <GridContainer style={{marginTop:"5%", marginBottom:"2%"}}>
                <GridItem xs={12} sm={12} md={2}></GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <p style={{color:"white",fontWeight:"bold", fontSize:"25px"}}>{value.length}</p>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}></GridItem>
                <GridItem xs={12} sm={12} md={4}>
                <p style={{color:"white",fontWeight:"bold", fontSize:"25px"}}>0</p>
                </GridItem>
              </GridContainer>
              <GridContainer>
              <GridItem xs={12} sm={12} md={2}></GridItem>
                <GridItem xs={12} sm={12} md={4}>
                <p style={{color:"#f6f6f6",fontWeight:"lighter", fontSize:"15px"}}>Total</p>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}></GridItem>
                <GridItem xs={12} sm={12} md={4}>
                <p style={{color:"#f6f6f6",fontWeight:"lighter", fontSize:"15px"}}>Active</p>
                </GridItem>
              </GridContainer>
            

            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card color="info">
            <CardBody>
            <p style={{color:"white",fontWeight:"bolder", fontSize:"16px"}}>Sales Demos Purchased</p>
              <GridContainer style={{marginTop:"5%", marginBottom:"2%"}}>
                <GridItem xs={12} sm={12} md={2}></GridItem>
                <GridItem xs={12} sm={12} md={4}>
                <p style={{color:"white",fontWeight:"bold", fontSize:"25px"}}>1</p>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}></GridItem>
                <GridItem xs={12} sm={12} md={4}>
                <p style={{color:"white",fontWeight:"bold", fontSize:"25px"}}>0</p>
                </GridItem>
              </GridContainer>
              <GridContainer>
              <GridItem xs={12} sm={12} md={2}></GridItem>
                <GridItem xs={12} sm={12} md={4}>
                <p style={{color:"#f6f6f6",fontWeight:"lighter", fontSize:"15px"}}>Total</p>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}></GridItem>
                <GridItem xs={12} sm={12} md={4}>
                <p style={{color:"#f6f6f6",fontWeight:"lighter", fontSize:"15px"}}>Active</p>
                </GridItem>
              </GridContainer>
            

            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card color="info">
            <CardBody>
            <p style={{color:"white",fontWeight:"bolder", fontSize:"16px"}}>PoC Library Stats</p>
              <GridContainer style={{marginTop:"5%", marginBottom:"2%"}}>
              <GridItem xs={12} sm={12} md={2}></GridItem>
                <GridItem xs={12} sm={12} md={4}>
                <p style={{color:"white",fontWeight:"bold", fontSize:"25px"}}>1</p>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}></GridItem>
                <GridItem xs={12} sm={12} md={4}>
                <p style={{color:"white",fontWeight:"bold", fontSize:"25px"}}>0</p>
                </GridItem>
              </GridContainer>
              <GridContainer>
              <GridItem xs={12} sm={12} md={2}></GridItem>
                <GridItem xs={12} sm={12} md={4}>
                <p style={{color:"#f6f6f6",fontWeight:"lighter", fontSize:"15px"}}>Total</p>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}></GridItem>
                <GridItem xs={12} sm={12} md={4}>
                <p style={{color:"#f6f6f6",fontWeight:"lighter", fontSize:"15px"}}>Active</p>
                </GridItem>
              </GridContainer>
            

            </CardBody>
          </Card>
        </GridItem>
       
      </GridContainer>
      <ViewOffering 
        name={"Learning Labs – Primer Series"}
        list={[
          "Understand the SD-WAN basics and cisco SD-WAN solution.",
          "On-Demand Lab access.",
          "Self paced learning focused on skills development.",
          "Prerequisite: 1-Day Cisco SD-WAN Foundations Virtual Session.",
          "Hands-on learning modules with scripted lab exercises."
        ]}
        icon={Learn}
        redirect={"/admin/dashboard"}
        button={"Go to Learning Labs"}

        />
        <ViewOffering 
        name={"Sales Demos"}
        list={[
          "Understand the SD-WAN use-cases through sales demo.",
          "On-Demand Lab access.",
          "Self paced learning focused on skills development.",
          "Showcase demo in single page"
        ]}
        icon={Sales}
        redirect={"/admin/dashboard"}
        button={"Go to Sales Demos"}

        />
        <ViewOffering 
        name={"PoC Library"}
        list={[
          "Experiment with self service deployments",
          "Prerequisite: Learning Labs – Primer Series and Scripted Demos.",
          "Test your workflow with realtime cloud orchestration.",
          "Develop & Integrate with multi-vendor solution."
        ]}
        icon={POC}
        redirect={"/admin/dashboard"}
        button={"Launch POC Library"}

        />
        <ViewOffering 
        name={"Designer"}
        list={[
          "Design your custom topology.",
          "On-Demand access.",
          "One click provisioning."
        ]}
        icon={Designer}
        redirect={"/admin/dashboard"}
        button={"Launch Designer"}

        />
      
    </div>
  );

}