import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/AccountBox";
import Email from "@material-ui/icons/Email";
import Back from "@material-ui/icons/ArrowBackRounded";
import User from "@material-ui/icons/SupervisedUserCircleTwoTone";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import auth from "auth/auth";
import { Api } from "config/api";
import logo1 from "assets/img/login/step1.png";
import logo2 from "assets/img/login/step2.png";
import logo3 from "assets/img/login/step3.png";
import resetToken from "config/resetToken";
import Axios from "axios";
import {  FormControl, InputLabel, OutlinedInput } from "@material-ui/core";
import StyledCheckbox from "components/StyledCheckbox/StyledCheckbox";
import Loader from "react-loader-spinner";
import Snackbar from "components/Snackbar/Snackbar";
//import { Api } from "config/api";

const useStyles = makeStyles(styles);

export default function LoginPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [allow, isAllow] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [islogin, setlogin] = React.useState(true);
  const [tr, setTR] = React.useState(true);
  const [alert, setAlert] = React.useState(null);
  const [em, setEmail] = React.useState("");
  const [us, setUser] = React.useState("");
  const [ac, setAccount] = React.useState("");
  const [ps, setPass] = React.useState("");
  const header = {
    "Content-type":"application/json",
    // "Access-Control-Request-Method":"*"
   // "Authorization":"token e921aa82cd806761cb1f1710c9a53b04b62670b4"
  }

  setTimeout(function() {
    setCardAnimation("");
  }, 700);

  const hideAlert = ()=>{
    setAlert(null)
  }
  const handleLogin = () =>{
    setLoader(true)
    Axios.post(Api.login,{"username":us,"password":ps}, {headers:header})
    .then((data)=>{
      
      Axios.get(Api.getUserProfile, {headers:{"Content-type":"application/json","Authorization":`token ${data.data.token}`}})
        .then((value)=>{
          localStorage.setItem('user', data.data.token)
          setLoader(false)
          value.data.privilege === "developer"?
          Axios.get(Api.getProfile, {headers:{"Content-type":"application/json","Authorization":`token ${data.data.token}`}})
            .then((value)=>{
              console.log(value.data.profiles)
              auth.perm(()=>{auth.permission = value.data.profiles})
              auth.login(()=> {props.history.push("/admin/dashboard")})
            })
            .catch(()=>{
              setLoader(false)
            }):auth.perm(()=>{auth.permission = "admin"})
            auth.login(()=> {props.history.push("/admin/dashboard")})


          
        })
        .catch((error)=>{
          setLoader(false)
        })
      
      
      
    }).catch(err=>{

      setLoader(false)
      setAlert(
            
        <Snackbar
            place="tr"
            color="danger"
            message={`Incorrect Credentials`}
            open={tr}
            closeNotification={()=>hideAlert()}
            close
        />
      );
    })
  }

  const handleInputAccount = e => {setAccount(e.target.value)}
  const handleInputUser = e => {setUser(e.target.value)}
  const handleInputEmail = e => {setEmail(e.target.value)}
  const handleInputPassword = e => {setPass(e.target.value)}

  

  const handleForget = () =>{
   
    const header = {
      "Content-type":"application/json",
    }
    Axios.post(Api.forgetPassword, {"username":us}, {headers:header})
      .then((data)=>{
        
        resetToken.generate(us, em)
        setlogin(true);
        alert("OK")
      }).catch(err =>{
        console.log(err);
      })
    
  }

  const classes = useStyles();

  const callback = (d) =>{
    isAllow(d);
  }
  return (
    <div className={classes.container}>
      
     {loader?
      <GridContainer justify="center">
            <GridItem md={6}>
                <div style={{ marginLeft:"40%"}}>
                <Loader 
                type="Rings"
                color="#067193"
                height={100}
                width={100}
                
                /></div>
            </GridItem>
      </GridContainer> :
      <GridContainer >
        {alert}
      <GridItem xs={12} sm={6} md={3}></GridItem>
        <GridItem xs={12} sm={6} md={6} >
         {islogin? <form>
            <Card login className={classes[cardAnimaton]}>
            <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="info"
              >
                <h4 className={classes.cardTitle}>User login</h4>
                
              </CardHeader>
             <div className={classes.cardBody}>
              <CardBody >
               
                <FormControl  fullWidth className={classes.margin}  variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-amount">Account</InputLabel>
                  <OutlinedInput
                  
                    onChange={handleInputAccount}
                    endAdornment={<InputAdornment position="end">
                    <Face className={classes.inputAdornmentIcon} />
                  </InputAdornment>}
                    labelWidth={60}
                  />
                </FormControl>
                <FormControl fullWidth className={classes.margin} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-amount">Username</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    
                    onChange={handleInputUser}
                    endAdornment={<InputAdornment position="end">
                    <Email className={classes.inputAdornmentIcon} />
                  </InputAdornment>}
                    labelWidth={60}
                  />
                </FormControl>
                <FormControl fullWidth className={classes.margin} variant="outlined" >
                  <InputLabel htmlFor="outlined-adornment-amount">Password</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    type="password"
                    onChange={handleInputPassword}
                    endAdornment={<InputAdornment position="end">
                    <Icon className={classes.inputAdornmentIcon}>
                      lock_outline
                    </Icon>
                  </InputAdornment>}
                    labelWidth={60}
                  />
                </FormControl>
                
                <p ><StyledCheckbox isChecked={callback}/> <span style={{fontSize:'11px', fontWeight:'bolder'}}>By Clicking here, I agree to <a style={{color:"#067193"}} href="http://criterionnetworks.com/terms-of-service/" target="blank" >Terms-of-Service</a> and <a style={{color:"#067193"}} href="http://criterionnetworks.com/privacy-policy/" target="blank">Privacy Policy</a> of Criterion Networks</span></p>
                <p onClick={()=>setlogin(false)} style={{color:"red"}}>forget password ?</p>
              
              
              </CardBody>
              
              <CardFooter className={classes.justifyContentCenter}>
                {allow?<Button onClick={() => handleLogin()} style={{background:"#067193"}} simple size="md" >
                  submit
                </Button>:<Button disabled style={{background:"#067193"}} simple size="md" >
                  submit
                </Button>}
                
              </CardFooter>
              
              </div>
            </Card>
          </form>:
          <form>
            <Card login className={classes[cardAnimaton]}>
            <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="info"
              >
               
                  <h4 className={classes.cardTitle}>Forget Password</h4>
               
                
              </CardHeader>
             <div className={classes.cardBody}>
              <CardBody >
              <FormControl  fullWidth className={classes.margin}  variant="outlined">
                  <InputLabel >Email</InputLabel>
                  <OutlinedInput
                
                    onChange={handleInputEmail}
                   
                    endAdornment={<InputAdornment position="end">
                    <Email style={{color:"#067193"}}/>
                  </InputAdornment>}
                    labelWidth={60}
                  />
                </FormControl>
                <FormControl fullWidth className={classes.margin} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-amount">Username</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    
                    onChange={handleInputUser}
                    endAdornment={<InputAdornment position="end">
                    <User style={{color:"#067193"}}/>
                  </InputAdornment>}
                    labelWidth={60}
                  />
                </FormControl>
              
                
              
              
              </CardBody>
              
              <CardFooter className={classes.justifyContentCenter}>
              <GridContainer>
                  <GridItem xs={4}>
                    <Button onClick={()=>setlogin(true)} simple size="sm" ><Back  className={classes.inputAdornmentIcon} /></Button>
                  </GridItem>
                  <GridItem xs={4}>
                  <Button onClick={() => handleForget()} style={{background:"#067193"}} simple size="md" >
                  submit
                </Button>
                  </GridItem>
                </GridContainer>
               
                
              </CardFooter>
              
              </div>
            </Card>
          </form>}
        </GridItem>
    
       
      </GridContainer>}
     
    </div>
  );
}
