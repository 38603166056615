import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Card, Button, Icon } from "@material-ui/core";
import Axios from "axios";
import { Api } from "config/api";

import LaunchPoC from "./LaunchPoC";
import ViewLibrary from "./ViewLibrary";

const buttonStyle = {
    color:"#067193",
    width:"6rem",
    backgroundColor:"#ffffff",
    borderColor:"#067193",

    
}

const buttonStyle2 = {
    
    width:"6rem",
    backgroundColor:"#067193",
    borderColor:"#ffffff",
    color:"#ffffff"

    
}

const launchStyle = {
    color:"#067193",
    width:"20rem",
    backgroundColor:"#ffffff",
    borderColor:"#067193",
    
}

export default function PoCLibrary(props) {
    const [view, setView] = React.useState(true);
    const [act, setActivity] = React.useState(1);

    const handleChange = () =>{
        setView(!view);
    }
    const handleActive = (v) =>{
        setActivity(v);
    }


   
    return(
        <div style={{marginTop:"30px"}}>
                <GridContainer>
               
                <GridItem xs={5} >
                    {view&&

                    <GridContainer>
                        <GridItem xs={1}>
                            <p style={{color:"#067193",marginTop:"8px",fontWeight:"bolder",fontSize:"16px"}}><b>Filter:</b></p>
                        </GridItem>
                        <GridItem xs={2}>
                        <Button onClick={()=>handleActive(3)} variant="outlined"  style={act === 3?buttonStyle2:buttonStyle}>Active</Button>
                        </GridItem>
                        <GridItem xs={2}>
                        <Button onClick={()=>handleActive(2)} variant="outlined"  style={act === 2?buttonStyle2:buttonStyle}>Inactive</Button>
                        </GridItem>
                        <GridItem xs={2}>
                        <Button onClick={()=>handleActive(1)} variant="outlined"  style={act === 1?buttonStyle2:buttonStyle}>All</Button>
                        </GridItem>
                    </GridContainer>
                   
                    
                    }
                </GridItem>
                <GridItem xs={3}>
                </GridItem>
                <GridItem xs={4} >
                    {view&&<Button onClick={handleChange} variant="outlined"  style={launchStyle}><Icon fontSize="default" color="secondary">center_focus_strong</Icon> &nbsp; Launch New PoCLibrary </Button>}
                </GridItem>
            </GridContainer> <br />
           {view? <div style={{marginTop:"20px"}}><ViewLibrary view={act} /></div>:<LaunchPoC handleBack={handleChange}/>}
            </div>
       
     
            
        
    );
}