import React from "react";

class MergeState {
  
 useMergeState(props) {
    const [state, setState] = React.useState(props);
    const setMergedState = newState => 
      setState(prevState => Object.assign({}, prevState, newState)
    );
    return [state, setMergedState];
  }
}

export default new MergeState()