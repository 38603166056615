import React, { Component } from 'react';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";


// material-UI imports
import FormLabel from "@material-ui/core/FormLabel";
import Close from "@material-ui/icons/Close";
import InputAdornment from "@material-ui/core/InputAdornment";

import formClasses from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import classes from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";



class CustomInputWrapper extends Component {
    buildAdornmentName = () => {
        return `${this.props.formField}_adornment`;
    }
    // form label is the label being shown in left of every form input. contains spaces.
    // form field is variable without spaces used for referencing.
    render() {
        return (
                <>
                <GridItem xs={12} sm={2}>
                    <FormLabel
                        style={{
                            ...formClasses.labelHorizontal,
                            ...InlineStyles.labelText
                        }}
                    >
                        {this.props.formLabel}
                    </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={7}>
                    <CustomInput
                        success={this.props.handleChildStateUpdates[this.buildAdornmentName] === "success"}
                        error={this.props.handleChildStateUpdates[this.buildAdornmentName] === "error"}
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            onChange: event => {
                                let stateUpdateObj = {};
                                if (this.props.verifyLength(event.target.value, 0,this.props.option!==undefined?this.props.option:null)) {
                                    stateUpdateObj[this.buildAdornmentName()] = "success";
                                    this.props.handleChildStateUpdates(stateUpdateObj);
                                } else {
                                    stateUpdateObj[this.buildAdornmentName()] = "error";
                                    this.props.handleChildStateUpdates(stateUpdateObj);
                                }
                                stateUpdateObj[this.props.formField] = event.target.value;
                                this.props.handleChildStateUpdates(stateUpdateObj);
                            },
                            type: this.props.inputType,
                            required: this.props.requiredField ? this.props.requiredField : false,
                            endAdornment:
                                this.props.adornmentState === "error" ? (
                                    <InputAdornment position="end">
                                        <Close style={formClasses.danger} />
                                    </InputAdornment>
                                ) : (
                                        undefined
                                    )
                        }}
                    />
                </GridItem>
            </>
        );
    }
}

const InlineStyles = {
    labelText: {
      color: "black"
    }
  };

export default CustomInputWrapper;