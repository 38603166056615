import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Card, Button, Icon } from "@material-ui/core";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { useHistory } from 'react-router-dom';
import moment from "moment";

import Axios from 'axios';
import { Api } from 'config/api';
import Loader from "react-loader-spinner";
import { header } from "config/api";

const buttonStyle = {
    color:"#067193",
    width:"6rem",
    backgroundColor:"#ffffff",
    borderColor:"#067193",
    
}

const fontStyle = {
    color:"#067193",
    fontSize:"15px",
    fontWeight:"bolder"
}

const buttonStyle2 = {
    color:"#067193",
    width:"12rem",
    backgroundColor:"#ffffff",
    borderColor:"#067193",
    
}

const TextShade = {color:"#a0b2b9", fontWeight:"bolder"}

export default function ViewLibrary(props) {
    

    const history = useHistory();
    const [sand, setSand] = React.useState([]);
    const [loader, setLoader] = React.useState(true);



    React.useEffect(()=>{
        Axios.get(Api.getClusterlist, {headers:header})
        .then((data)=>{
            console.log(data.data)
          setSand(data.data.clouds)
          setLoader(false)
        }).catch(err=>{
          console.log(err);
        })

    },[]);

    const handleDetail = (x)=>{
        if(x !== null){
            history.push('/admin/poc/detail'+'?uuid='+x)
        } 
    }
    function TimeDiff(name) {
        var hours = ''
        sand.map((x, index)=>{
          
            if(x.name === name){
                let time = new Date(x.expiry_date)
    
            let ms = moment(time).diff(moment(Date()));
            let d = moment.duration(ms);
            let hrs = Math.floor(d.asHours())+" Hours   " + moment.utc(ms).format("mm")+" Minutes";
            console.log(hrs);
            hours = hrs
            }    
        })
        return hours
        
    } 


    const ViewPOC = sand.map((x, index)=>
    props.view-1 === 1?"":x.deleted === false&&
        <div key={index}>
        <Card >
            <CardHeader style={{backgroundColor:x.lab_status==="ready"?"#0da1d0":"#067193", color:"#ffffff"}}>
               <h4><b>{x.clustername}</b></h4>
            </CardHeader>
            <CardBody>
                <GridContainer style={{padding:"10px"}}>
                    <GridItem xs={2}>
                        <p style={fontStyle}><b>{TimeDiff(x.name)}</b></p>
                        <p style={TextShade}>Run Time</p>
                    </GridItem>
                   <GridItem xs={1}></GridItem>
                    <GridItem xs={2}>
                        <p style={fontStyle}><b>{x.user_name}</b></p>
                        <p style={TextShade}>User</p>
                    </GridItem>
                    <GridItem xs={3}>
                    </GridItem>
                    
                    <GridItem xs={2}>
                        <Button style={buttonStyle2} onClick={()=>handleDetail(x.lab_uuid)} variant="outlined"> Terminate</Button>
                    </GridItem>
                    <GridItem xs={2}>
                        <Button style={buttonStyle2} onClick={()=>handleDetail(x.uuid)} variant="outlined">Return To Library</Button>
                    </GridItem>
                </GridContainer>

               
            </CardBody>
        </Card><br />
        </div>
    )
   
   
    return(
        <div>
        {loader?
            <GridContainer justify="center">
                <GridItem md={6}>
                    <div style={{marginTop:"20%", marginLeft:"40%"}}>
                    <Loader 
                    type="Rings"
                    color="#067193"
                    height={100}
                    width={100}
                    
                    /></div>
                </GridItem>
            </GridContainer>:

            
        <GridContainer>
           
            <GridItem xs={12} >
               {ViewPOC}
            </GridItem>
           
        </GridContainer>}</div>
        
    );
}