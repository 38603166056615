import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Icon from "@material-ui/core/Icon";



import ReactMarkdown from 'react-markdown';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";


import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";


import Axios from "axios";
import { Api } from "config/api";
import { Paper } from "@material-ui/core";
import Loader from "react-loader-spinner";
import { header } from "config/api";
import auth from "auth/auth";

const fontStyle = {
  color:"#067193",
  fontSize:"15px",
  fontWeight:"bolder"
}

const fontStyle2 = {
  color:"#067193",
  fontSize:"17px",
  fontWeight:"bolder"
}

const buttonStyle = {
  fontSize:"15px",
  backgroundColor:"#067193",
  color:"#ffffff",
  fontWeight:"bolder"
}


const useStyles = makeStyles(styles);

export default function LaunchLabs(props) {
  const classes = useStyles();
  const [labs, ViewLabs] = React.useState([]);
  const [ty, SetTypes] = React.useState([]);
  const [rule, setRule] = React.useState([]);
  const [cID, setCID] = React.useState(1);
  const [loader, setLoader] = React.useState(true);


const handleChange = ()=>{
    props.handleBack()
}

  React.useEffect(()=>{
    console.log(auth.permission[0].permissions);
    Axios.get(Api.ViewLearningLab, {headers:header})
    .then((data)=>{
        console.log(data.data)
      ViewLabs(data.data)
      if(auth.permission[0].permissions === undefined){
        setRule(auth.permission)
      }else{
        setRule(auth.permission[0].permissions)
      }
      
      
      setLoader(false)
    }).catch(err=>{
      console.log(err);
    })

    Axios.get(Api.adminLabTypes, {headers:header})
    .then((data)=>{
        console.log(data.data)
      SetTypes(data.data)
    }).catch(err=>{
      console.log(err);
    })


    },[]);

    const handleLab = (event)=>{
        setCID(event)
    }

    const isAllowed = (name) =>{
        let allow = false
        if(rule === "admin"){
            allow = true
        }else{
            rule.map((x, index)=>{
                if(name === x.resource_name){
                    allow = true
                }
            })

        }
        

        return allow
    }

    const CategoryLab = ty.map((x, index)=>
        x.type==="learnings"&&
        <GridItem>
             <div style={{padding:"5px"}}>
        <Button  onClick={()=>handleLab(x.id)} style={{backgroundColor:cID ===x.id?"#067193":"#ffffff", color:cID===x.id?"#ffffff":"#067193", fontWeight:"bolder"}}>
            <b>{x.name}</b>
        </Button>
        </div>
        </GridItem>
    )

  return (
    <div>
    {loader?
            <GridContainer justify="center">
                <GridItem md={6}>
                    <div style={{marginTop:"20%", marginLeft:"40%"}}>
                    <Loader 
                    type="Rings"
                    color="#067193"
                    height={100}
                    width={100}
                    
                    /></div>
                </GridItem>
            </GridContainer>:
            <div>
            <GridContainer justify="center">
                <GridItem xs={1}>
                <Button variant="outlined"  onClick={handleChange} style={{color:"#067193",backgroundColor:"#f6f6f6", fontWeight:"bolder", marginTop:"20px"}}><Icon>arrow_back</Icon> Back</Button>
                </GridItem>
                <GridItem xs={1}>
                
                    <p style={{color:"#067193", fontWeight:"bolder", marginTop:"30px"}}><b>Select Category</b></p>
                </GridItem>
                <GridItem >
                <Paper style={{padding:"5px"}} elevation={0} square>
                <GridContainer>
                    
                    {CategoryLab}
                
                </GridContainer>
                
                </Paper>
                </GridItem>
            </GridContainer>
            <hr />
            <GridContainer>
                
                    {labs.map((y, index2)=>
                        y.admin_lab_types_id === cID&&isAllowed(y.identifier)&&
                        
                        <GridItem xs={12} sm={12} md={12}>
                            <div style={{width:"86%", marginLeft:"50px", marginTop:"15px"}}>
                            <Paper elevation={1}>
                                <GridContainer>
                                    <GridItem xs={3}>
                                    <div style={{paddingLeft:"10px"}}>
                                    <div product className={classes.cardHover}>
                                            <CardHeader image className={classes.cardHeaderHover}>
                                                <img src={y.image===""?"https://s3-us-west-2.amazonaws.com/sdcloud-imgs/general/LearningLab2.png":y.image} alt="..." style={{height:"200px"}}/>
                                            </CardHeader>
                                    </div>
                                    </div>
                                    </GridItem>
                                    
                                    <GridItem xs={9}>
                                        <GridContainer>
                                            <GridItem xs={6}>
                                            <div style={{padding:"2px",marginTop:"20px"}}>
                                            
                                            <img style={{width:"15px"}} src={"https://sdcloud-beta.criterionnetworks.com/criterion/img/icons/learnings/certification.png"} alt="..."/><b style={{color:"#067193",fontSize:"16px",fontWeight:"bolder"}}> {" "} {y.short_title}</b> 
                                            
                                            </div>
                                            
                                            <hr />
                                            </GridItem>
                                            <GridItem>
                                                <div style={{marginTop:"25px"}}>
                                                    <p style={fontStyle}>Run Time : <b style={fontStyle}>{y.lab_run_time} </b></p>
                                                </div>
                                            </GridItem>
                                        </GridContainer>
                                        <br />
                                        <GridContainer>
                                            <GridItem>
                                                <div style={{padding:"10px",fontSize:"14px",color:"#2a99bd",fontWeight:"bolder"}}>
                                                    <ReactMarkdown source={y.description} />
                                                </div>
                                            </GridItem>
                                        </GridContainer>
                                        
                                        <GridContainer>
                                        <hr />
                                        
                                        <GridItem>
                                            <div style={{padding:"10px"}}> 
                                        
                                            <Button style={buttonStyle} ><Icon  color="#ffffff">launch</Icon> &nbsp;Launch</Button></div>
                    
                                            </GridItem>
                                            <GridItem>
                                        
                                            <div style={{padding:"10px"}}><Button style={buttonStyle}><Icon color="#ffffff">schedule</Icon> &nbsp;Schedule</Button></div> 
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                </GridContainer>
                            </Paper>
                            <br />
                            <br />
                            </div>
                        </GridItem>
                    )}
            </GridContainer>
            </div>
        }
    </div>
  );
}