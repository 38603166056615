import  jwt from 'jsonwebtoken';
import sendMail from './sendMail';

class ResetToken {

    generate(cb, cb2) {
        let token = jwt.sign({data: {"user":cb, "email":cb2}}, 'cn', { expiresIn: '1h' });
        console.log(token);
        let mail = sendMail.send(cb2, token)
        return mail
    }
    
    decyrpt(cb) {
        
      let v =  jwt.verify(cb, 'cn', function(err, decoded) {
            if (err) {
                
                return null
               
            } else {
             
        
              return decoded.data
         
            }
        });
        
        return v
    }

  
}

export default new ResetToken();