import React from "react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import { FormControl, InputLabel, OutlinedInput, InputAdornment, makeStyles, Icon, Button, Select } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import Axios from "axios";
import CardFooter from "components/Card/CardFooter";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Back from "@material-ui/icons/ArrowBackRounded"
import { Quest } from "views/Pages/resetHelper/question";
import { Api } from "config/api";

const useStyles = makeStyles(styles);

export default function UpdateSec(props) {
    const [cardAnimaton, setCardAnimation] = React.useState("cardGrow");
  const [qt, setPass1] = React.useState(null);
  const [at, setPass2] = React.useState(null);
  setTimeout(function() {
    setCardAnimation("");
  }, 1);
    const classes = useStyles();
    const handleSubmit = () =>{
        // update password API
     
          const header = {
           "Content-type":"application/json",
           "Authorization":localStorage.getItem("user")
         }
         Axios.post(Api.storeSecurityQs, {"username":props.username, "question":qt, "answer":at}, header)
         .then((data)=>{
           
           alert("done")
         }).catch(err =>{
           console.log(err);
           alert("Try Again ")
         })
     
       
       }
     
       const handleInputQues = e => {setPass1(e.target.value)}
       const handleInputPassword2 = e => {setPass2(e.target.value)}
    const handleCallback = () =>{
        props.callback(false);
    } 
       
    return(
        <Card >
        <CardHeader>
            <div ><p style={{color:"#067193", fontWeight:"bolder"}}><b>Update Security Question</b></p></div>
          </CardHeader>
      
          <CardBody >
          <FormControl fullWidth className={classes.margin} variant="outlined" >
          <InputLabel  htmlFor="outlined-age-native-simple">Select Question</InputLabel>
                        <Select
                        native
                        id="outlined-age-native-simple"
                       
                        onChange={handleInputQues}
                        
                     labelWidth={60}
                        
                        >
                            {Quest.map((x, index)=>
                                <option key={index} value={x} >{x}</option>
                            )}
                        
                     
                    </Select>
                </FormControl>
         
                <FormControl fullWidth className={classes.margin} variant="outlined">
                   <InputLabel  htmlFor="outlined-adornment-amount">Answer</InputLabel>
                   <OutlinedInput
                     id="outlined-adornment-amount"
                
                     onChange={handleInputPassword2}
                     
                     labelWidth={60}
                   />
                 </FormControl>
          
          </CardBody>
          
          <CardFooter className={classes.justifyContentCenter}>
          <GridContainer>
              <GridItem xs={4}>
                <Button onClick={handleCallback} simple size="sm" ><Back  className={classes.inputAdornmentIcon} /></Button>
              </GridItem>
              <GridItem xs={4}>
              <Button onClick={() => handleSubmit()} style={{background:"#067193", color:"white"}} simple size="md" >
              update
            </Button>
              </GridItem>
            </GridContainer>
           
            
          </CardFooter>
          

        </Card>
    )
}