import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// react component for creating dynamic tables
import ReactTable from "react-table";

import moment from "moment";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Assignment from "@material-ui/icons/QueryBuilderTwoTone";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Axios from "axios";
import { Api } from "config/api";
import { header } from "config/api";
import auth from "auth/auth";
import CardIcon from "components/Card/CardIcon";

const styles = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center"
  },
  cardCategory: {
    margin: "0",
    color: "#999999"
  }
};

const useStyles = makeStyles(styles);

export default function Activitylog(props) {
  const classes = useStyles();
  const [logs, setLog] = React.useState([])
  const [user, setUser] = React.useState("")

  
  React.useEffect(()=>{
    
    (async () => {
     await Axios.get(Api.getUserProfile, {headers:header})
          .then((value)=>{
            
            Axios.post(Api.logRecord,{
              "query":`{ logRecords(first:1,last:1000000, name:"${value.data.username}", action:\"\"){ createdAt, action, privilege, requestUser, level, msg, ip, location, browser, offeringType }}`
              } ,{headers:header})
              .then((value)=>{
              NewTabs(value.data.data.logRecords);
              })
              .catch((err)=>{
              console.log(err)
              })
          })
          .catch(()=>{
          
          })
    })();
  },[])

  function SortData(data, sort) {
    let sorted = []
    let NewSorted = []
    data.map((x, index)=>{
      sort === x.action && sorted.push(x)
    });

    return sorted
  }

  

  function NewTabs(data) {
    let NavPills = []
    let TabPills = []
    data.map((y, index2)=>{
        TabPills.push(y.action)
    })
    let unique = [...new Set(TabPills)];
    unique.map((z, index)=>{
      let tab = {
        tabButton: z,
        tabContent: (
          <div style={{color:"#067193", fontWeight:"bolder", padding:"10px"}}>
            <hr />
            <ReactTable
                data={SortData(data, z)}
                style={{marginTop:"20px"}} 
                           
                columns={[
                
                  {
                    Header: "Timestamp",
                    accessor: "createdAt",
                    filterable:true
                  },
                  {
                      Header: "Level",
                      accessor: "level",
                      filterable:true
                  },
                  {
                    Header: "Message",
                    accessor: "msg",
                    filterable:true
                  },
                  
                  {
                    Header: "Offering Type",
                    accessor: "offeringType",
                    filterable:true
                  
                  },
                  {
                    Header: "IP Adress",
                    accessor: "ip",
                    filterable:true
                  },"",
                ]}
                defaultPageSize={5}
                showPaginationTop={true}
                showPaginationBottom={false}
                className="-striped -highlight"
                />
              </div>
          
        )
      }

      NavPills.push(tab)
    
    })
    setLog(NavPills)
    
  }

  return (
    <div>
      <GridContainer>
        
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
            <CardIcon color="info">
              <Assignment />
            </CardIcon>
              <h4 className={classes.cardTitle}>
                Activity logs 
              </h4>
            </CardHeader>
            <CardBody>
              <NavPills
                color="info"
                tabs={logs}
              />
            </CardBody>
          </Card>
        </GridItem>
       
      </GridContainer>
   
    </div>
  );
}