import React, { Component } from 'react';
// importing styles for components
import classes from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import formClasses from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

// core components
import GridItem from "components/Grid/GridItem.js";

// material-UI imports
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

class CustomDropDownWrapper extends Component {
    
    // form label is the label being shown in left of every form input. contains spaces.
    // form field is variable without spaces used for referencing.

    render() {
        return (
            <>
                <GridItem xs={12} sm={2}>
                      <FormLabel
                        style={{
                          ...formClasses.labelHorizontal,
                          ...InlineStyles.labelText
                        }}
                      >
                        {this.props.formLabel}
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={7}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        ></InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          value={this.props.value}
                          onChange={event =>
                            this.props.handleDropDownChange(event, this.props.formField)
                          }
                          inputProps={{
                            name: "simpleSelect"
                          }}
                        >
                          {this.props.menu.map((menuItemVal, index) => {
                            return (
                              <MenuItem
                                key={index}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value={menuItemVal}
                              >
                                {menuItemVal}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </GridItem>
            </>
        );
    }
}

const InlineStyles = {
    labelText: {
      color: "black"
    }
  };


export default CustomDropDownWrapper;