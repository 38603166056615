const BASE_URL_CMS = "http://localhost:8000";// process.env.CMS_API_URL 
const BASE_URL ="https://beta-backend.criterionnetworks.com"; // process.env.BACKEND_API_URL 

console.log(process.env.CMS_API_URL);

const token = localStorage.getItem('user')
export const Api = {
    resetPassword: BASE_URL+"/reset_password",
    login: BASE_URL + "/authorize/",
    forgetPassword: BASE_URL +"/forgetPassword/",
    getUserProfile: BASE_URL+"/getUserData",
    getProfile: BASE_URL+"/get_profile",
    updateUserProfile : BASE_URL + "/updateUserData/",
    storeSecurityQs: BASE_URL + "/security/store_qa/",
    validateSecurityQs : BASE_URL + "/security/validate_security_qa/",
    getSecurityQs : BASE_URL + "/security/get_security_qa/",
    getScheduleCluster: BASE_URL + "/list_gcp_scheduler_tasks/",
    delScheduleCluster : BASE_URL + "/delete_gcp_schedule_task/",
    getNewLearning: BASE_URL + "/get_newlearnings",
    getClusterlist: BASE_URL + "/clusterlist",
    provisonCluster: BASE_URL + "/provision/",
    viewPoCLibraryDetail: BASE_URL + "/",
    getResource: BASE_URL + "/get_resources",
    logRecord : BASE_URL + "/logrecords/",
    viewPoCLibrary: BASE_URL_CMS + "/poc/admin_sandboxes/",
    viewPoCLibraryTopo: BASE_URL_CMS + "/poc/admin_sandboxes_details/",
    addHours: BASE_URL + "/add_hours",
    viewSalesDemo: BASE_URL_CMS + "/sales/admin_sales_demos/",
    viewSalesDemoIntro: BASE_URL_CMS + "/sales/admin_sales_demos_course_demos/",
    viewSalesDemoUseCase: BASE_URL_CMS + "/sales/admin_sales_demos_use_cases/",
    viewSalesDemoCheetSheet: BASE_URL_CMS + "/sales/admin_sales_demos_cheat_sheets/",
    ViewLearningLab: BASE_URL_CMS + "/learnings/admin_learnings/",
    adminLabTypes: BASE_URL_CMS + "/general/admin_lab_types/"

}

export const header = {
    "Content-type":"application/json",
    "Authorization":`Token ${token}`
  }

