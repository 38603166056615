
import Dashboard from "views/Dashboard/Dashboard.js";

// import Apps from "@material-ui/icons/Apps";
import DashboardIcon from "@material-ui/icons/Dashboard";
// import DateRange from "@material-ui/icons/DateRange";
// import GridOn from "@material-ui/icons/GridOn";
import Cached from "@material-ui/icons/CachedTwoTone";
import Activity from "@material-ui/icons/ScheduleTwoTone";
import Schedule from "@material-ui/icons/EventNoteTwoTone";
import Stats from "@material-ui/icons/EqualizerTwoTone";
import Service from "@material-ui/icons/LocalOfferTwoTone";
// import Timeline from "@material-ui/icons/Timeline";
// import WidgetsIcon from "@material-ui/icons/Widgets";
import CreateNode from "views/ServiceLifeCycle/views/CreateNode";
import LoginPage from "views/Pages/LoginPage";
import ResetPage from "views/Pages/ResetPage";
import UserProfile from "views/Pages/UserProfile";
import ScheduleCluster from "views/ScheduleCluster/ScheduleCluster";
import Activitylog from "views/Activitylog/Activitylog";
import PoCLibrary from "views/ServiceOffering/PoCLibrary/PoCLibrary";
import LaunchPoC from "views/ServiceOffering/PoCLibrary/LaunchPoC";
import NewPocLib from "views/ServiceOffering/PoCLibrary/NewPoCLib";
import SalesDemo from "views/ServiceOffering/SalesDemo/SalesDemo";
import NewSalesDemo from "views/ServiceOffering/SalesDemo/NewSalesDemo";
import LearningLab from "views/ServiceOffering/LearningLab/LearningLab";
import MyProfile from "views/MyProfile/MyProfile";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
    view: true,
  },
  {
    path: "/login-page",
    name: "login",
    icon: DashboardIcon,
    view: false,
    component: LoginPage,
    layout: "/auth"
  },
  {
    path: "/reset-page",
    name: "reset",
    icon: DashboardIcon,
    view: false,
    component: ResetPage,
    layout: "/auth"
  },
  {
    collapse: true,
    name: "Service Offering",
    icon: Service,
    state: "tablesCollapse",
    views: [
      {
        path: "/learning-lab",
        name: "Learning Lab",
        mini: "-",
        view: true,
        component: LearningLab,
        layout: "/admin"
      },
      {
        path: "/sales-demo",
        name: "SalesDemo",
        mini: "-",
        view: true,
        component: SalesDemo,
        layout: "/admin"
      },
      {
        path: "/poc-library",
        name: "POC Library",
        mini: "-",
        view: true,
        component: PoCLibrary,
        layout: "/admin"
      },
      {
        path: "/sales/detail",
        name: "SalesDemo",
        mini: "-",
        view: false,
        component: NewSalesDemo,
        layout: "/admin"
      },
      {
        path: "/poc/detail",
        name: "POC Library",
        mini: "-",
        view: false,
        component: NewPocLib,
        layout: "/admin"
      },
      {
        path: "/react-tables",
        name: "My Povs",
        mini: "-",
        view: true,
        component: Dashboard,
        layout: "/admin"
      },
      {
        path: "/react-tables",
        name: "Designer",
        mini: "-",
        view: true,
        component: Dashboard,
        layout: "/admin"
      }
    ]
  },
  {
    collapse: true,
    name: "Service Life Cycle",
    icon: Cached,
    state: "pageCollapse",
    views: [
      {
        path: "/create-node",
        view: true,
        name: "Create Node",
        mini: "-",
        component: CreateNode,
        layout: "/admin"
      }
    ]
  },
  {
    path: "/statistics",
    name: "My Statistics",
    icon: Stats,
    view: true,
    component: ResetPage,
    layout: "/admin"
  },
  {
    path: "/activity-logs",
    name: "Activity logs",
    icon: Activity,
    view: true,
    component: Activitylog,
    layout: "/admin"
  },
  {
    path: "/schedule-cluster",
    name: "My Scheduled Clusters",
    icon: Schedule,
    view: true,
    component: ScheduleCluster,
    layout: "/admin"
  },
  {
    path: "/profile",
    name: "My Profile",
    icon: Stats,
    view: false,
    component: MyProfile,
    layout: "/admin"
  },
  {
    path: "/reset-page",
    name: "User Management",
    icon: DashboardIcon,
    view: false,
    component: ResetPage,
    layout: "/admin"
  }
  

];
export default dashRoutes;
