import React from "react";

import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";

import CardBody from "components/Card/CardBody.js";



export default function ViewOffering(props) {
  
  const [list, setList] = React.useState([])


  React.useEffect(()=>{
 
        setList(props.list)

  },[]);
 
  return (
 
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={2}>
                  <div style={{marginTop:"12%", marginLeft:"25%"}}>
                    <img src={props.icon}/>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <div style={{color:"#067193"}}>
                    <h4 style={{marginTop:"1%",marginBottom:"2%", fontWeight:"bold"}}>{props.name}</h4>
                   <ul class="fa-ul" >
                       {list.map((x, key)=>
                            
                           key < 2 && <li style={{marginBottom:"5%"}}><span class="fa-li"><i style={{color:"#58d85e"}} class="fas fa-check-square"></i></span>{x}</li>
                       )}
                      
                     
                    </ul>
                    
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <div style={{color:"#067193",marginTop:"8%"}}>
                   
                   <ul class="fa-ul">
                      
                       {list.map((x, key)=>
                          key > 1 && <li style={{marginBottom:"5%"}}><span class="fa-li"><i style={{color:"#58d85e"}} class="fas fa-check-square"></i></span>{x}</li>
                       )}
                    </ul>
                    
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                <div style={{marginTop:"10%"}}><Button color="info">{props.button}</Button></div>
                </GridItem>
                
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      
      </GridContainer>
      
      
  
  );

}
