import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import AddAlert from "@material-ui/icons/AddAlert";
import Close from "@material-ui/icons/Close";

// @material-ui/icons
import MuiAlert from '@material-ui/lab/Alert';
import Email from "@material-ui/icons/Email";
import User from "@material-ui/icons/SupervisedUserCircleTwoTone";
import Answer from "@material-ui/icons/QuestionAnswer"
// import LockOutline from "@material-ui/icons/LockOutline";

// core components

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import Axios from "axios";
import {  FormControl, InputLabel, OutlinedInput, Select } from "@material-ui/core";
import { Quest } from "./question";
import { Api } from "config/api";




const useStyles = makeStyles(styles);


export default function SecurityQues(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");

  const [q, Ques] = React.useState("");
  const [aler, setAlert] = React.useState(false);
  const [qt, setQt] = React.useState(null);
  const [at, setAt] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [qs, setQues] = React.useState(false);
  const [qi, setQuesIn] = React.useState(false);
 

  setTimeout(function() {
    setCardAnimation("");
  }, 700);



  const handleInputAns = e => {setAt(e.target.value)}
  const handleInputQues = e => {setQt(e.target.value)}
  
  React.useEffect(()=>{
      
      props.askProps.status == 200 && setQues(true)

      if(qs){
        Ques(props.askProps.data);
        console.log(q.question);
      }
   
  });

  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const callback1 = () =>{
    
   //validate qa
   if(at !=null){
    const header = {
        "Content-type":"application/json",
      }
      Axios.post(Api.validateSecurityQs, {"username":props.username, "question":q.question, "answer":at}, header)
        .then((data)=>{
     
          
          props.isOk(true)
        
        }).catch(err =>{
         
       
        })
    }else{
        setQuesIn(true)
    }
      
  }

  const callback2 = () =>{
    if(qt !=null && at !=null){
       
        const header = {
        "Content-type":"application/json",
      }
      Axios.post(Api.storeSecurityQs, {"username":props.username, "question":qt, "answer":at}, header)
        .then((data)=>{
          
          props.isOk(true)

        }).catch(err =>{
          console.log(err);
          alert("Try Again ")
        })

    }else{
        setQuesIn(true)
    }
   
   }
  return (
    <div className={classes.container}>
      <GridContainer >
     
      
        <GridItem xs={12} sm={6} md={6} style={{marginTop:"10%"}}>
         
          {qs?<form>
            <Card login className={classes[cardAnimaton]}>
            <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="info"
              >
               
                  <h4 className={classes.cardTitle}>Answer Security Question</h4>
               
                
              </CardHeader>
             <div className={classes.cardBody}>
              <CardBody >
             
                  
                 <div style={{padding:"10px"}}><p style={{fontSize:"20px"}}>{q.question }</p></div>
              
                <FormControl fullWidth className={classes.margin} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-amount">Answer</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    error={qi}
                    onChange={handleInputAns}
                    endAdornment={<InputAdornment position="end">
                    <User className={classes.inputAdornmentIcon} />
                  </InputAdornment>}
                    labelWidth={60}
                  />
                </FormControl>
              
                
              
              
              </CardBody>
              
              <CardFooter className={classes.justifyContentCenter}>
              <GridContainer>
                  
                  <GridItem xs={4}>
                  <Button onClick={() => callback1()} style={{background:"#067193"}} simple size="md" >
                  submit
                </Button>
                  </GridItem>
                </GridContainer>
               
                
              </CardFooter>
              
              </div>
            </Card>
          </form>:
             <form>
             <Card login className={classes[cardAnimaton]}>
             <CardHeader
                 className={`${classes.cardHeader} ${classes.textCenter}`}
                 color="info"
               >
                
                   <h4 className={classes.cardTitle}>Set Security Question</h4>
                
                 
               </CardHeader>
              <div className={classes.cardBody}>
               <CardBody >
               
                 <FormControl fullWidth  className={classes.margin} variant="outlined">
                    <InputLabel  htmlFor="outlined-age-native-simple">Select Question</InputLabel>
                        <Select
                        native
                        id="outlined-age-native-simple"
                       
                        onChange={handleInputQues}
                        
                     labelWidth={60}
                        
                        >
                            {Quest.map((x, index)=>
                                <option key={index} value={x} >{x}</option>
                            )}
                        
                     
                    </Select>
                 </FormControl>
                 <FormControl fullWidth className={classes.margin} variant="outlined">
                   <InputLabel error={qi}  htmlFor="outlined-adornment-amount">Answer</InputLabel>
                   <OutlinedInput
                     id="outlined-adornment-amount"
                     error={qi}
                     onChange={handleInputAns}
                     endAdornment={<InputAdornment position="end">
                     <Answer className={classes.inputAdornmentIcon} />
                   </InputAdornment>}
                     labelWidth={60}
                   />
                 </FormControl>
               
                 
               
               
               </CardBody>
               
               <CardFooter className={classes.justifyContentCenter}>
               <GridContainer>
                   
                   <GridItem xs={4}>
                   <Button onClick={() => callback2()} style={{background:"#067193"}} simple size="md" >
                   submit
                 </Button>
                   </GridItem>
                 </GridContainer>
                
                 
               </CardFooter>
               
               </div>
             </Card>
           </form>}
        </GridItem>
       
      </GridContainer>
    </div>
  );
}
