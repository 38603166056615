import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import auth from "auth/auth";
import logo1 from "assets/img/login/step1.png";
import logo2 from "assets/img/login/step2.png";
import logo3 from "assets/img/login/step3.png";
import resetToken from "config/resetToken";
import Axios from "axios";

import SecurityQues from "./resetHelper/SecurityQues";
import UpdatePass from "./resetHelper/UpdatePass";
import { Api } from "config/api";

const useStyles = makeStyles(styles);

export default function ResetPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [allow, isAllow] = React.useState(false);
  const [pass, isPass] = React.useState(true);
  const [ask, isAsk] = React.useState([]);
  const [cred, Credential] = React.useState();
 


  setTimeout(function() {
    setCardAnimation("");
  }, 700);
 
  const u = window.location.search.split("=")[1]

  React.useEffect(()=>{
 
    const v = resetToken.decyrpt(u);
    Credential(v);
    //get_security_api

    const header = {
      "Content-type":"application/json",
    }
    if(v != null){
      
      Axios.post(Api.getSecurityQs, {"username":v.user}, header)
      .then((data)=>{
        isAsk(data)
        isAllow(true)
        
      }).catch(function (err) {
        console.log(err)  
      })
      
    }   
  },[])

 
  const classes = useStyles();

  const callback = (prop) =>{
    isPass(!prop);
  }
  return (
    <div className={classes.container}>
      <GridContainer >
        <GridItem xs={12} sm={6} md={6} style={{marginTop:"2%"}}>
          {allow?pass?<SecurityQues isOk={callback} username={cred.user} askProps={ask}/>:<UpdatePass user={cred.user} email={cred.email}/>:<h1 style={{color:"red"}}>Token Expires </h1>}
        </GridItem>
        <GridItem xs={12} sm={6} md={1}></GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <ul >
         <li> <img src={logo1} className={classes.sideline}/> </li>
         <li> <img src={logo2} className={classes.sideline}/> </li>
         <li> <img src={logo3} className={classes.sideline}/> </li>
          </ul>
        </GridItem>
      </GridContainer>
    </div>
  );
}
