import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Unlock from "@material-ui/icons/LockOpen";
import Settings from "@material-ui/icons/Settings";
import Info from "@material-ui/icons/Info";
import LocationOn from "@material-ui/icons/LocationOn";
import Gavel from "@material-ui/icons/Gavel";
import HelpOutline from "@material-ui/icons/HelpOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Accordion from "components/Accordion/Accordion.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Axios from "axios";
import { Api } from "config/api";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";
import moment from "moment";
import { Button } from "@material-ui/core";
import parse from 'html-react-parser';
import ReactMarkdown from 'react-markdown';
import { header } from "config/api";

const styles = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center"
  },
  cardCategory: {
    margin: "0",
    color: "#999999"
  }
};

const useStyles = makeStyles(styles);


export default function NewPocLib(props) {
  const classes = useStyles();
  const [detail, setDetail] = React.useState([]);
  const [value, setValue]  =  React.useState([]);
  const [top, setTopo]  =  React.useState([]);

  const u = window.location.search.split("=")[1]


  
  React.useEffect(()=>{

      Axios.get(Api.viewPoCLibraryDetail+u+"/details/", {headers:header})
        .then((value)=>{
          console.log(value.data);
          setDetail([value.data]);
        })
        .catch((err)=>{
          console.log(err)
        })

        Axios.get(Api.viewPoCLibrary, {"Content-Type":"application/json"})
            .then((data)=>{
                setValue(data.data);
            }).catch((err)=>{
                console.log(err);
            })
        
        Axios.get(Api.viewPoCLibraryTopo, {"Content-Type":"application/json"})
            .then((data)=>{
                console.log(data.data);
                setTopo(data.data);
            }).catch((err)=>{
                console.log(err);
            })
  },[])

  const TimeNew = detail.map((x, index)=>{
    let time = new Date(x.provision_time)

    return time.toString()
  })

  const TimeDiff = detail.map((x, index)=>{
    let time = new Date(x.provision_time)

    let ms = moment(Date()).diff(moment(time));
    let d = moment.duration(ms);
    let hrs = Math.floor(d.asHours())+" Hrs : " + moment.utc(ms).format("mm")+" Min";
   

    return hrs
  })

  const TopologyID = detail.map((x, index)=>{
    value.map((y, inde)=>{
        if(x.sdnctrl === y.identity){
          console.log(y.id);
            return y.id
        }
    })
  })

  const IPAddress = detail.map((x, index)=>{
      let p="0.0.0.0"
      for (p in x.ipdetails){

        console.log(x.ipdetails[p]);

        return (x.ipdetails[p]).toString()
      }

 
  })


  const Topology = top.map((x, index)=>
      x.admin_sandboxes_id === 8&&parse((x.access_device).split("viptela-underlay1").join(IPAddress))
       
  )

  
  const IPTable = top.map((x, index)=>
      x.admin_sandboxes_id === 8&&
      <ReactMarkdown source={(x.ip_details_access).split("viptela-underlay1").join(IPAddress)}/>)
        

  const Overview = top.map((x, index)=>
  x.admin_sandboxes_id === 8&&
  <ReactMarkdown source={x.overview}/>
   )

  const NavPillComp = [].map(()=>
    <GridContainer>
      <GridItem xs={8}>
      </GridItem>
    </GridContainer>
  
  )

  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6}>
          <Card >
              <CardBody>
                <GridContainer>
               
                  <GridItem xs={6}>
              
                     <p  style={{fontWeight:"bolder",fontSize:"16px"}}>Running Time : <Button style={{color:"white",backgroundColor:"#0da1d0" ,fontSize:"16px"}}>{TimeDiff}</Button></p>
                      {/* {detail.map((x, index)=> <b>{TimeNew}</b>)} */}
                     
                  </GridItem>
                  <GridItem xs={4}>
                  
                  {detail.map((x, index)=>
                    <p style={{fontWeight:"bolder", fontSize:"16px"}}>Status : {x.status} ({x.percentage})</p>
                  )}
                  {detail.map((x, index)=>
                    <CustomLinearProgress
                    variant="determinate"
                    color="info"
                    value={x.percentage}

                  />
                  )}
                  
                  </GridItem>
                </GridContainer>
              </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info">
            {detail.map((x, index)=>
              <p style={{color:"white",fontWeight:"bolder", fontSize:"16px"}}>
                 <b> {x.clustername}</b>
              </p>
            
            )}  
            </CardHeader>
            <CardBody>
              <NavPills
                color="info"
                horizontal={{
                  tabsGrid: { xs: 12, sm: 12, md: 2 },
                  contentGrid: { xs: 12, sm: 12, md: 10 }
                }}
                tabs={[
                  {
                    tabButton: "Access Devices",
                    tabIcon: Unlock,
                  tabContent: (
            
              <NavPills
                color="warning"
                tabs={[
                  {
                    tabButton: "Topology",
                    tabContent: (
                     
                        <GridContainer>
                          <GridItem xs={8}>
                            {Topology}
                          </GridItem>
                        </GridContainer>
                    
                    )
                  },
                  {
                    tabButton: "IP Details",
                    tabContent: (
                      <span>
                        {IPTable}
                      </span>
                    )
                  },
                  {
                    tabButton: "Overview",
                    tabContent: (
                      <GridContainer>
                      <GridItem xs={8}>
                        {Overview}
                      </GridItem>
                    </GridContainer>
                        
                   
                    )
                  },

                ]}
              />
              )},
                  {
                    tabButton: "PoC Library Settings",
                    tabIcon: Settings,
                    tabContent: (
                      <span>
                        <p>
                          Efficiently unleash cross-media information without
                          cross-media value. Quickly maximize timely
                          deliverables for real-time schemas.
                        </p>
                        <br />
                        <p>
                          Dramatically maintain clicks-and-mortar solutions
                          without functional solutions. Dramatically visualize
                          customer directed convergence without revolutionary
                          ROI. Collaboratively administrate empowered markets
                          via plug-and-play networks. Dynamically procrastinate
                          B2C users after installed base benefits.
                        </p>
                      </span>
                    )
                  }
                ]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}