import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Card, Button, Icon, Divider } from "@material-ui/core";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { useHistory } from 'react-router-dom';
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";

import Axios from 'axios';
import { Api } from 'config/api';

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import Loader from "react-loader-spinner";
import { header } from "config/api";

const useStyles = makeStyles(styles);

const buttonStyle = {
    color:"#067193",
    width:"6rem",
    backgroundColor:"#ffffff",
    borderColor:"#067193",
    
}

const fontStyle = {
    color:"#067193",
    fontSize:"15px",
    fontWeight:"bolder"
}

const buttonStyle2 = {
    color:"#067193",
    width:"12rem",
    backgroundColor:"#ffffff",
    borderColor:"#067193",
    
}

const TextShade = {color:"#a0b2b9", fontWeight:"bolder"}

export default function ViewLearningLabs(props) {
    

    const history = useHistory();
    const [learn, setLearn] = React.useState([]);
    const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
    const [hrs, setHrs] = React.useState([]);
    const [loader, setLoader] = React.useState(true);


    React.useEffect(()=>{
        Axios.get(Api.getNewLearning, {headers:header})
        .then((data)=>{
            console.log(data.data)
          setLearn(data.data.learnings)
          setLoader(false)
        }).catch(err=>{
          console.log(err);
        })

    },[]);

    const handleDetail = (x)=>{
        if(x !== null){
            history.push('/admin/sales/detail'+'?uuid='+x)
        }
    }

    setTimeout(function() {
        setCardAnimation("");
      }, 700);

    function TimeDiff(name) {
     
        let time = new Date(name)
    
        let ms = moment(time).diff(moment(Date()));
        let d = moment.duration(ms);
        let hrs = Math.floor(d.asHours())+" Hours   " + moment.utc(ms).format("mm")+" Minutes";
            
        
        return hrs
        
    } 
const classes = useStyles();


    const ViewLearningLabs = learn.map((x, index)=>
    props.view-1 === 1?"":x.is_scripted_demo != true&&<div key={index}>
        <Card login className={classes[cardAnimaton]}>
            <CardHeader style={{backgroundColor:x.lab_status==="ready"?"#0da1d0":"#067193", color:"#ffffff"}}>
               <h4><b>{x.display_name===null?x.name:x.display_name}</b></h4>
            </CardHeader>
            <CardBody>
                <GridContainer style={{padding:"10px"}}>
                    
                    <GridItem xs={2}>
                        <p style={fontStyle}><b>{TimeDiff(x.expiry_date)}</b></p>
                        <p style={TextShade}>Time Left</p>
                    </GridItem>
                    <GridItem xs={2} >
                       <div style={{marginTop:"-5px"}}> <p style={fontStyle}>{x.is_active&&<b><Icon fontSize="small">done</Icon>Active</b>}</p>
                        <p style={TextShade}>Demo Status</p>  </div>
                    </GridItem>
                    <GridItem xs={2}>
                        <p style={fontStyle}><b>{x.lab_status}</b></p>
                        <p style={TextShade}>Cluster Status</p>
                    </GridItem>
                    <GridItem xs={2}>
                        <p style={fontStyle}><b>{x.user_name}</b></p>
                        <p style={TextShade}>User</p>
                    </GridItem>
                    
                    <GridItem xs={1}>
                    </GridItem>
                    <GridItem xs={2}>
                        <Button style={buttonStyle2} onClick={()=>handleDetail(x.lab_uuid)} variant="outlined">Labs</Button>
                    </GridItem>
                </GridContainer>

               
            </CardBody>
        </Card><br />
        </div>
    )
   
   
    return(
  <div>
{loader?
            <GridContainer justify="center">
                <GridItem md={6}>
                    <div style={{marginTop:"20%", marginLeft:"40%"}}>
                    <Loader 
                    type="Rings"
                    color="#067193"
                    height={100}
                    width={100}
                    
                    /></div>
                </GridItem>
            </GridContainer>:
            
        <GridContainer>
  
            <GridItem xs={12} >
               {ViewLearningLabs}
               <Divider />
            </GridItem>
     
            
        </GridContainer>}
    </div>
        
    );
}