export const Quest = [
    "",
    "What primary school did you attend ",
    "In what town or city was your first full time job ",
    "What is your mother's maiden name ",
    "What was your childhood nickname ",
    "What was your dream job as a child ",
    "What is your pet's name ",
    "What is the name of the company of your first job ",
    "What is the name of your favorite childhood friend "

];

export const SupportQues = [
    "",
    "Learning Labs ",
    "POC Library ",
    "Designer ",
    "Sales Demo ",
    "Others "
]