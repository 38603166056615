import React, { Component } from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import SweetAlert from "react-bootstrap-sweetalert";

// material-UI imports
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Close from "@material-ui/icons/Close";
import InputAdornment from "@material-ui/core/InputAdornment";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

import axios from 'axios';
// importing helper Components
import CustomInputWrapper from "../components/CustomInputWrapper";
import CustomDropDownWrapper from "../components/CustomDropDownWrapper"

//importing constant files
import {
  FORM_FIELDS,
  FORM_LABELS,
  CATEGORY,
  NO_OF_VCPU,
  RAM,
  DISK,
  CPU_TYPE,
  DEV_TYPE,
  INTERFACE_TYPE,
  ADDITIONAL_INTERFACE
} from "../utils/constants/constants_createnode";

// importing url file
import { CREATE_NODE_URLS } from "../utils/constants/urls"

// importing styles for components
import classes from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import formClasses from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

export default class CreateNode extends Component {
  constructor(props) {
    super(props);
    let adornmentFlagObj = Object.keys(FORM_FIELDS).reduce((accum, val) => {
      accum[val + "_adornment"] = "success";
      return accum;
    }, {});
    this.state = {
      requiredState: "",
      ...FORM_FIELDS,
      ...adornmentFlagObj,
      showNotification: false,
      // notificationMessage: "",
      // notificationColor: "info",
      showAlert: false,
      alertMessage: "",
      formSubmitStatus: "false",
      isAllFormFieldsValid: "true",
      showSubmitButton:true
    };
  }

  //show notification for 6 seconds
  // showNotification = (message = "", duration = 6000, color = "info", redirectToDashboard = false) => {
  //   this.setState({
  //     showNotification: true,
  //     notificationMessage: message,
  //     notificationColor: color
  //   });
  //   setTimeout(() => {
  //     this.setState({
  //       showNotification: false
  //     });
  //     if (redirectToDashboard == true) {
  //       this.redirectToDashboard();
  //     }
  //   }, duration);
  // }

  redirectToDashboard = () => {
    console.log("redirected");
    this.props.history.replace("/admin/dashboard");
  };

  handleSubmitForm = event => {
    event.preventDefault();
    const formFieldskeys = Object.keys(FORM_FIELDS);

    // filtering only required formfields from state and building formObj
    const formObj = Object.keys(this.state).reduce((accum, val) => {
      if (formFieldskeys.includes(val)) {
        accum[val] = this.state[val];
        return accum;
      }
      return accum;
    }, {});

    // checkFormValidation
    // formObj is a beautiful json of form fields.
    if (this.state.isAllFormFieldsValid) {
      this.callCreateNodeApi(formObj);
    } else { 
      this.setState({
        showAlert: true,
        alertMessage: "Please enter a valid input.",
        formSubmitStatus: false
      });
      setTimeout(this.hideAlert, 1500);
    }
    console.log(JSON.stringify(formObj, undefined, 4));
  };

  callCreateNodeApi = (data) => {
    let timeoutDuration = 4500;
    this.setState({ showSubmitButton: false });
    axios({
      method: "post",
      url: CREATE_NODE_URLS.CREATE_NODE_URL,
      headers: {
        authorization: "Token " + "429a30fa0e4e95cb79189651059d190a4d1e2593"
      },
      data: data
    }).then(res => {
      if (res.status == 201) {
        this.setState({ showAlert: true, alertMessage: "Node created successfully.", formSubmitStatus: true, showSubmitButton:true });
        // this.showNotification("Node has been created successfully.", 3000, "success", true);
      }
      console.log(res.data);
    }).catch(err => {
      this.setState({ showAlert: true, alertMessage: "Error creating node!", formSubmitStatus: false, showSubmitButton:true });
      // this.showNotification("Error while creating node. Please try again.", 6000, "danger");
      timeoutDuration = 2500;
      console.log(err.response.data.message);
    })
    setTimeout(this.hideAlert, timeoutDuration);
  }

  // handles closing of notification
  // handleCloseNotfication = () => {
  //   this.setState({
  //     showNotification: false
  //   });
  // }

  // handle closing of alert
  hideAlert = () => {
    this.setState({ showAlert: false });
    if (this.state.formSubmitStatus) this.redirectToDashboard();
  }

  //handle state updates passed from custom child components to create node parent
  handleChildStateUpdates = (stateUpdateObj) => {
    this.setState(stateUpdateObj);
  }

  // function that verifies if a string has a given length or not
  verifyLength = (value, length, option = null) => {
    if (option === "version") {
      const regex = "^[0-9]*\\.?[0-9]{1,}$";
      const found = value.match(regex);
      if (found) {
        this.setState({ isAllFormFieldsValid: true });
        return true;
      } else {
        this.setState({ isAllFormFieldsValid: false });
        return false;
      }
    }
    if (value.length > length) {
      return true;
    }
    return false;
  };

  // handle drop downs in the form.
  handleDropDownChange = (event, select_id) => {
    let update_state = {};
    update_state[select_id] = event.target.value;
    this.setState(update_state);
  };

  // handle applciation name change in add another.
  handleApplicationNameChange = (event, index, eventType) => {
    let oldState, newState;
    oldState =
      eventType === "externalservices_setting"
        ? this.state.externalservices_setting
        : this.state.configuration_setting; // an array
    let settingObj = oldState[index];
    settingObj["application_name"] = event.target.value; // chngin input field
    oldState[index] = settingObj;
    newState = oldState;

    if (eventType === "externalservices_setting") {
      this.setState({
        externalservices_setting: newState
      });
    } else if (eventType === "configuration_setting") {
      this.setState({
        configuration_setting: newState
      });
    }
  };

  // handle port number change in add another.
  handlePortChange = (event, index, eventType) => {
    let oldState, newState;
    oldState =
      eventType === "externalservices_setting"
        ? this.state.externalservices_setting
        : this.state.configuration_setting; // an array
    let settingObj = oldState[index];
    settingObj["port"] = event.target.value;
    oldState[index] = settingObj;
    newState = oldState;

    if (eventType === "externalservices_setting") {
      this.setState({
        externalservices_setting: newState
      });
    } else if (eventType === "configuration_setting") {
      this.setState({
        configuration_setting: newState
      });
    }
  };

  // handle protocol change drop down in settings field.
  handleProtocolChange = (event, setting_type, index) => {
    let oldState, newState;
    oldState =
      setting_type === "configuration_setting"
        ? this.state.configuration_setting
        : this.state.externalservices_setting; // an array
    let settingObj = oldState[index];
    settingObj["protocol"] = event.target.value;
    settingObj["application_name"] = settingObj["protocol"] + index;
    oldState[index] = settingObj;
    newState = oldState;

    if (setting_type === "configuration_setting") {
      this.setState({
        configuration_setting: newState
      });
    } else if (setting_type === "externalservices_setting") {
      this.setState({
        externalservices_setting: newState
      });
    }
  };

  // function to handle add another click to configuration services
  handleAddAnotherConfigServices = () => {
    let oldState = this.state.configuration_setting;
    oldState.push({
      id: Object.keys(oldState).length,
      application_name: "Http" + Object.keys(oldState).length.toString(),
      protocol: "Http",
      port: 0
    });
    let newState = oldState;
    this.setState({
      configuration_setting: newState
    });
  };

  // function to handle add another click to external services
  handleAddAnotherExtServices = () => {
    let oldState = this.state.externalservices_setting;
    oldState.push({
      id: Object.keys(oldState).length,
      application_name: "Tcp" + Object.keys(oldState).length.toString(),
      protocol: "Tcp",
      port: 0
    });
    let newState = oldState;
    this.setState({
      externalservices_setting: newState
    });
  };

  //handle delete settings
  handleDeleteSettings = (index, setting) => {

    if (setting === "configuration_setting") {
      let copy_state = this.state.configuration_setting;
      copy_state.splice(index, 1);
      this.setState({
        configuration_setting: copy_state
      });
    } else if (setting === "externalservices_setting") {
      let copy_state = this.state.externalservices_setting;
      copy_state.splice(index, 1);
      this.setState({
        externalservices_setting: copy_state
      });
    }
  }

  render() {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="info">Create Node</CardIcon>
              {/* <h4 style={classes.cardIconTitle}>Create Node</h4> */}
            </CardHeader>
            <CardBody>
              <GridItem xs={12} sm={12} md={12}>
                {" "}
                <form onSubmit={this.handleSubmitForm}>
                  <GridContainer justify="center">
                    <CustomInputWrapper
                      formLabel={FORM_LABELS.name}
                      formField="name"
                      adornmentState={this.state.name_adornment}
                      inputType="text"
                      requiredField={true}
                      verifyLength={this.verifyLength}
                      handleChildStateUpdates={this.handleChildStateUpdates}
                    ></CustomInputWrapper>
                  </GridContainer>

                  <GridContainer justify="center">
                    <CustomInputWrapper
                      formLabel={FORM_LABELS.description}
                      formField="description"
                      adornmentState={this.state.description_adornment}
                      inputType="text"
                      requiredField={true}
                      verifyLength={this.verifyLength}
                      handleChildStateUpdates={this.handleChildStateUpdates}
                    ></CustomInputWrapper>
                  </GridContainer>
                  <GridContainer justify="center">
                    <CustomInputWrapper
                      formLabel={FORM_LABELS.version}
                      formField="version"
                      adornmentState={this.state.version_adornment}
                      inputType="text"
                      requiredField={true}
                      verifyLength={this.verifyLength}
                      handleChildStateUpdates={this.handleChildStateUpdates}
                      option="version"
                    ></CustomInputWrapper>
                  </GridContainer>
                  <GridContainer justify="center">
                    <CustomDropDownWrapper
                      formLabel={FORM_LABELS.category}
                      formField="category"
                      handleDropDownChange={this.handleDropDownChange}
                      value={this.state.category}
                      menu={CATEGORY}
                    >
                    </CustomDropDownWrapper>
                  </GridContainer>
                  <GridContainer justify="center">
                    <CustomInputWrapper
                      formLabel={FORM_LABELS.display_url}
                      formField="display_url"
                      adornmentState={this.state.display_url_adornment}
                      inputType="url"
                      requiredField={true}
                      verifyLength={this.verifyLength}
                      handleChildStateUpdates={this.handleChildStateUpdates}
                    ></CustomInputWrapper>
                  </GridContainer>
                  <GridContainer justify="center">
                    <CustomInputWrapper
                      formLabel={FORM_LABELS.image_url}
                      formField="image_url"
                      adornmentState={this.state.image_url_adornment}
                      inputType="url"
                      requiredField={true}
                      verifyLength={this.verifyLength}
                      handleChildStateUpdates={this.handleChildStateUpdates}
                    ></CustomInputWrapper>
                  </GridContainer>
                  <GridContainer justify="center">
                    <CustomInputWrapper
                      formLabel={FORM_LABELS.user_name}
                      formField="user_name"
                      adornmentState={this.state.user_name_adornment}
                      inputType="text"
                      requiredField={true}
                      verifyLength={this.verifyLength}
                      handleChildStateUpdates={this.handleChildStateUpdates}
                    ></CustomInputWrapper>
                  </GridContainer>
                  <GridContainer justify="center">
                    <CustomInputWrapper
                      formLabel={FORM_LABELS.password}
                      formField="password"
                      adornmentState={this.state.password_adornment}
                      inputType="password"
                      requiredField={true}
                      verifyLength={this.verifyLength}
                      handleChildStateUpdates={this.handleChildStateUpdates}
                    ></CustomInputWrapper>
                  </GridContainer>
                  <GridContainer justify="flex-start" spacing={0}>
                    <GridItem xs={4} sm={1}>
                      <Button justIcon round color="info">
                        <i className="material-icons">arrow_forward</i>
                      </Button>
                    </GridItem>
                    <GridItem xs={8} sm={11}>
                      <h4> Image Settings </h4>
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify="center">
                    <CustomDropDownWrapper
                      formLabel={FORM_LABELS.no_of_vcpu}
                      formField="no_of_vcpu"
                      handleDropDownChange={this.handleDropDownChange}
                      value={this.state.no_of_vcpu}
                      menu={NO_OF_VCPU}
                    >
                    </CustomDropDownWrapper>
                  </GridContainer>
                  <GridContainer justify="center">
                    <CustomDropDownWrapper
                      formLabel={FORM_LABELS.ram}
                      formField="ram"
                      handleDropDownChange={this.handleDropDownChange}
                      value={this.state.ram}
                      menu={RAM}
                    >
                    </CustomDropDownWrapper>
                  </GridContainer>
                  <GridContainer justify="center">
                    <CustomDropDownWrapper
                      formLabel={FORM_LABELS.disk}
                      formField="disk"
                      handleDropDownChange={this.handleDropDownChange}
                      value={this.state.disk}
                      menu={DISK}
                    >
                    </CustomDropDownWrapper>
                  </GridContainer>
                  <GridContainer justify="center">
                    <CustomDropDownWrapper
                      formLabel={FORM_LABELS.cpu_type}
                      formField="cpu_type"
                      handleDropDownChange={this.handleDropDownChange}
                      value={this.state.cpu_type}
                      menu={CPU_TYPE}
                    >
                    </CustomDropDownWrapper>
                  </GridContainer>
                  <GridContainer justify="center">
                    <CustomDropDownWrapper
                      formLabel={FORM_LABELS.dev_type}
                      formField="dev_type"
                      handleDropDownChange={this.handleDropDownChange}
                      value={this.state.dev_type}
                      menu={DEV_TYPE}
                    >
                    </CustomDropDownWrapper>
                  </GridContainer>
                  <GridContainer justify="center">
                    <CustomDropDownWrapper
                      formLabel={FORM_LABELS.interface_type}
                      formField="interface_type"
                      handleDropDownChange={this.handleDropDownChange}
                      value={this.state.interface_type}
                      menu={INTERFACE_TYPE}
                    >
                    </CustomDropDownWrapper>
                  </GridContainer>
                  <GridContainer justify="center">
                    <CustomInputWrapper
                      formLabel={FORM_LABELS.management_interface}
                      formField="management_interface"
                      adornmentState={this.state.management_interface_adornment}
                      inputType="text"
                      requiredField={true}
                      verifyLength={this.verifyLength}
                      handleChildStateUpdates={this.handleChildStateUpdates}
                    ></CustomInputWrapper>
                  </GridContainer>
                  <GridContainer justify="center">
                    <CustomDropDownWrapper
                      formLabel={FORM_LABELS.additional_interface}
                      formField="additional_interface"
                      handleDropDownChange={this.handleDropDownChange}
                      value={this.state.additional_interface}
                      menu={ADDITIONAL_INTERFACE}
                    >
                    </CustomDropDownWrapper>
                  </GridContainer>
                  <GridContainer justify="center">
                    <CustomInputWrapper
                      formLabel={FORM_LABELS.max_nodes}
                      formField="max_nodes"
                      adornmentState={this.state.max_nodes_adornment}
                      inputType="number"
                      requiredField={true}
                      verifyLength={this.verifyLength}
                      handleChildStateUpdates={this.handleChildStateUpdates}
                    ></CustomInputWrapper>
                  </GridContainer>
                  <GridContainer justify="flex-start" spacing={0}>
                    <GridItem xs={4} sm={1}>
                      <Button justIcon round color="info">
                        <i className="material-icons">arrow_forward</i>
                      </Button>
                    </GridItem>
                    <GridItem xs={8} sm={11}>
                      <h4> Post Init Script </h4>
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify="center" alignItems="center">
                    <GridItem xs={12} sm={2}>
                      <FormLabel
                        style={{
                          ...formClasses.labelHorizontal,
                          ...InlineStyles.labelText
                        }}
                      >
                        Post Init Script
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={7}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <TextareaAutosize
                          rows={4}
                          aria-label="maximum height"
                          placeholder="Paste your init script here."
                          onChange={event =>
                            this.setState({ post_inst_script: event.target.value })
                          }
                          value={this.state.post_inst_script}
                        />
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify="flex-start" spacing={0}>
                    <GridItem xs={4} sm={1}>
                      <Button justIcon round color="info">
                        <i className="material-icons">arrow_forward</i>
                      </Button>
                    </GridItem>
                    <GridItem xs={8} sm={11}>
                      <h4> Configuration Settings </h4>
                    </GridItem>
                  </GridContainer>
                  {this.state.configuration_setting.map(
                    (configuration_setting_obj, index) => {
                      return (
                        <>
                          <GridContainer direction="row" justify="center" alignItems="center">
                            <GridItem xs={11} sm={11} >
                              <GridContainer justify="center" key={index}>
                                <GridItem xs={12} sm={2}>
                                  <FormLabel
                                    style={{
                                      ...formClasses.labelHorizontal,
                                      ...InlineStyles.labelText
                                    }}
                                  >
                                    Application Name
                              </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={7}>
                                  <CustomInput
                                    success={this.state.requiredState === "success"}
                                    error={this.state.requiredState === "error"}
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      onChange: event => {
                                        if (
                                          this.verifyLength(event.target.value, 0)
                                        ) {
                                          this.setState({
                                            requiredState: "success"
                                          });
                                        } else {
                                          this.setState({
                                            requiredState: "error"
                                          });
                                        }
                                        this.handleApplicationNameChange(
                                          event,
                                          index,
                                          "configuration_setting"
                                        );
                                      },
                                      type: "text",
                                      value: this.state.configuration_setting[index]
                                        .application_name,
                                      endAdornment:
                                        this.state.requiredState === "error" ? (
                                          <InputAdornment position="end">
                                            <Close style={formClasses.danger} />
                                          </InputAdornment>
                                        ) : (
                                            undefined
                                          )
                                    }}
                                  />
                                </GridItem>
                              </GridContainer>
                              <GridContainer justify="center">
                                <GridItem xs={12} sm={2}>
                                  <FormLabel
                                    style={{
                                      ...formClasses.labelHorizontal,
                                      ...InlineStyles.labelText
                                    }}
                                  >
                                    Protocol
                              </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={7}>
                                  <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}
                                  >
                                    <InputLabel
                                      htmlFor="simple-select"
                                      className={classes.selectLabel}
                                    ></InputLabel>
                                    <Select
                                      MenuProps={{
                                        className: classes.selectMenu
                                      }}
                                      classes={{
                                        select: classes.select
                                      }}
                                      value={
                                        this.state.configuration_setting[index][
                                        "protocol"
                                        ]
                                      }
                                      onChange={event =>
                                        this.handleProtocolChange(
                                          event,
                                          "configuration_setting",
                                          index
                                        )
                                      }
                                      inputProps={{
                                        name: "simpleSelect"
                                      }}
                                    >
                                      <MenuItem
                                        disabled
                                        classes={{
                                          root: classes.selectMenuItem
                                        }}
                                      >
                                        Choose Protocol
                                  </MenuItem>
                                      <MenuItem
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected: classes.selectMenuItemSelected
                                        }}
                                        value="Http"
                                      >
                                        Http
                                  </MenuItem>
                                      <MenuItem
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected: classes.selectMenuItemSelected
                                        }}
                                        value="SSH"
                                      >
                                        SSH
                                  </MenuItem>
                                    </Select>
                                  </FormControl>
                                </GridItem>
                              </GridContainer>
                              <GridContainer justify="center">
                                <GridItem xs={12} sm={2}>
                                  <FormLabel
                                    style={{
                                      ...formClasses.labelHorizontal,
                                      ...InlineStyles.labelText
                                    }}
                                  >
                                    Port
                              </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={7}>
                                  <CustomInput
                                    success={this.state.requiredState === "success"}
                                    error={this.state.requiredState === "error"}
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      onChange: event => {
                                        if (
                                          this.verifyLength(event.target.value, 0)
                                        ) {
                                          this.setState({
                                            requiredState: "success"
                                          });
                                        } else {
                                          this.setState({
                                            requiredState: "error"
                                          });
                                        }
                                        this.handlePortChange(
                                          event,
                                          index,
                                          "configuration_setting"
                                        );
                                      },
                                      type: "number",
                                      value: this.state.configuration_setting[index]
                                        .port,
                                      endAdornment:
                                        this.state.requiredState === "error" ? (
                                          <InputAdornment position="end">
                                            <Close style={formClasses.danger} />
                                          </InputAdornment>
                                        ) : (
                                            undefined
                                          )
                                    }}
                                  />
                                </GridItem>
                              </GridContainer>
                              {index ===
                                this.state.configuration_setting.length - 1 ? null : (
                                  <GridContainer>
                                    <GridItem xs={12} sm={1}></GridItem>
                                    <GridItem xs={12} sm={11}>
                                      {" "}
                                      <hr />{" "}
                                    </GridItem>
                                  </GridContainer>
                                )}
                            </GridItem>

                            <GridItem xs={1} sm={1}>
                              {index !== 0 ? (<Button
                                justIcon
                                round
                                style={{ backgroundColor: "black" }}
                                size="sm"
                                onClick={() => this.handleDeleteSettings(index, "configuration_setting")}
                              >
                                <i className="material-icons">delete</i>
                              </Button>) : null}
                            </GridItem>
                          </GridContainer>
                        </>
                      );
                    }
                  )}
                  <GridContainer>
                    <GridItem xs={2} sm={1}></GridItem>
                    <GridItem xs={2} sm={1}>
                      <Button
                        justIcon
                        round
                        style={{ backgroundColor: "black" }}
                        size="sm"
                        onClick={this.handleAddAnotherConfigServices}
                      >
                        <i className="material-icons">add</i>
                      </Button>
                    </GridItem>
                    <GridItem xs={8} sm={10}>
                      <h5> Add Another </h5>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={4} sm={1}>
                      <Button justIcon round color="info">
                        <i className="material-icons">arrow_forward</i>
                      </Button>
                    </GridItem>
                    <GridItem xs={8} sm={11}>
                      <h4> External Services Settings </h4>
                    </GridItem>
                  </GridContainer>
                  {this.state.externalservices_setting.map(
                    (externalservices_setting_id, index) => {
                      return (
                        <>
                          <GridContainer direction="row" justify="center" alignItems="center">
                            <GridItem xs={11} sm={11} >
                              <GridContainer justify="center" key={index}>
                                <GridItem xs={12} sm={2}>
                                  <FormLabel
                                    style={{
                                      ...formClasses.labelHorizontal,
                                      ...InlineStyles.labelText
                                    }}
                                  >
                                    Application Name
                              </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={7}>
                                  <CustomInput
                                    success={this.state.requiredState === "success"}
                                    error={this.state.requiredState === "error"}
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      onChange: event => {
                                        if (
                                          this.verifyLength(event.target.value, 0)
                                        ) {
                                          this.setState({
                                            requiredState: "success"
                                          });
                                        } else {
                                          this.setState({
                                            requiredState: "error"
                                          });
                                        }
                                        this.handleApplicationNameChange(
                                          event,
                                          index,
                                          "externalservices_setting"
                                        );
                                      },
                                      type: "text",
                                      value: this.state.externalservices_setting[
                                        index
                                      ].application_name,
                                      endAdornment:
                                        this.state.requiredState === "error" ? (
                                          <InputAdornment position="end">
                                            <Close style={formClasses.danger} />
                                          </InputAdornment>
                                        ) : (
                                            undefined
                                          )
                                    }}
                                  />
                                </GridItem>
                              </GridContainer>
                              <GridContainer justify="center">
                                <GridItem xs={12} sm={2}>
                                  <FormLabel
                                    style={{
                                      ...formClasses.labelHorizontal,
                                      ...InlineStyles.labelText
                                    }}
                                  >
                                    Protocol
                              </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={7}>
                                  <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}
                                  >
                                    <InputLabel
                                      htmlFor="simple-select"
                                      className={classes.selectLabel}
                                    ></InputLabel>
                                    <Select
                                      MenuProps={{
                                        className: classes.selectMenu
                                      }}
                                      classes={{
                                        select: classes.select
                                      }}
                                      value={
                                        this.state.externalservices_setting[index][
                                        "protocol"
                                        ]
                                      }
                                      onChange={event =>
                                        this.handleProtocolChange(
                                          event,
                                          "externalservices_setting",
                                          index
                                        )
                                      }
                                      inputProps={{
                                        name: "simpleSelect"
                                      }}
                                    >
                                      <MenuItem
                                        disabled
                                        classes={{
                                          root: classes.selectMenuItem
                                        }}
                                      >
                                        Choose Protocol
                                  </MenuItem>
                                      <MenuItem
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected: classes.selectMenuItemSelected
                                        }}
                                        value="Tcp"
                                      >
                                        Tcp
                                  </MenuItem>
                                      <MenuItem
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected: classes.selectMenuItemSelected
                                        }}
                                        value="Udp"
                                      >
                                        Udp
                                  </MenuItem>
                                    </Select>
                                  </FormControl>
                                </GridItem>
                              </GridContainer>
                              <GridContainer justify="center">
                                <GridItem xs={12} sm={2}>
                                  <FormLabel
                                    style={{
                                      ...formClasses.labelHorizontal,
                                      ...InlineStyles.labelText
                                    }}
                                  >
                                    Port
                              </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={7}>
                                  <CustomInput
                                    success={this.state.requiredState === "success"}
                                    error={this.state.requiredState === "error"}
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      onChange: event => {
                                        if (
                                          this.verifyLength(event.target.value, 0)
                                        ) {
                                          this.setState({
                                            requiredState: "success"
                                          });
                                        } else {
                                          this.setState({
                                            requiredState: "error"
                                          });
                                        }
                                        this.handlePortChange(
                                          event,
                                          index,
                                          "externalservices_setting"
                                        );
                                      },
                                      type: "number",
                                      value: this.state.externalservices_setting[
                                        index
                                      ].port,
                                      endAdornment:
                                        this.state.requiredState === "error" ? (
                                          <InputAdornment position="end">
                                            <Close style={formClasses.danger} />
                                          </InputAdornment>
                                        ) : (
                                            undefined
                                          )
                                    }}
                                  />
                                </GridItem>
                              </GridContainer>
                              {index ===
                                this.state.externalservices_setting.length -
                                1 ? null : (
                                  <GridContainer>
                                    <GridItem xs={12} sm={1}></GridItem>
                                    <GridItem xs={12} sm={10}>
                                      {" "}
                                      <hr />{" "}
                                    </GridItem>
                                  </GridContainer>
                                )}
                            </GridItem>
                            <GridItem xs={1} sm={1} >
                              {index !== 0 ? (<Button
                                justIcon
                                round
                                style={{ backgroundColor: "black" }}
                                size="sm"
                                onClick={() => this.handleDeleteSettings(index, "externalservices_setting")}
                              >
                                <i className="material-icons">delete</i>
                              </Button>) : null}
                            </GridItem>
                          </GridContainer>
                        </>
                      );
                    }
                  )}
                  <GridContainer>
                    <GridItem xs={2} sm={1}></GridItem>
                    <GridItem xs={2} sm={1}>
                      <Button
                        justIcon
                        round
                        style={{ backgroundColor: "black" }}
                        size="sm"
                        onClick={this.handleAddAnotherExtServices}
                      >
                        <i className="material-icons">add</i>
                      </Button>
                    </GridItem>
                    <GridItem xs={8} sm={10}>
                      <h5> Add Another </h5>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    {this.state.showSubmitButton ? (<Button type="submit" color="info">
                      Submit
                    </Button>) : <p> creating node. . . </p> }
                  </GridContainer>
                </form>
              </GridItem>
            </CardBody>
          </Card>
        </GridItem>
        <GridContainer>
          {this.state.showAlert ? (<SweetAlert
            success={this.state.formSubmitStatus}
            warning={!this.state.formSubmitStatus}
            style={sweetAlertStyle}
            title={this.state.alertMessage}
            onConfirm={this.hideAlert}
            showConfirm={false}
          >
            {this.state.formSubmitStatus ? "Redirecting to dashboard. . ." : ""}
          </SweetAlert>) : null}
        </GridContainer>
      </GridContainer>
    );
  }
}

const InlineStyles = {
  labelText: {
    color: "black"
  }
};

const sweetAlertStyle = {
  position: "fixed",
  top: "50%",
  left: "50%",
  marginTop: "-9em", /*set to a negative number 1/2 of your height*/
  marginLeft: "-15em"
}
