export const FORM_FIELDS = {
  name: "",
  description: "",
  version: "",
  category: "Custom",
  display_url: "",
  image_url: "",
  user_name: "",
  password: "",
  no_of_vcpu: "1",
  ram: "1",
  disk: "10",
  cpu_type: "Pass Through",
  dev_type: "virtIO",
  interface_type: "e1000",
  management_interface: "",
  additional_interface: "1",
  max_nodes: "",
  post_inst_script: "",
  configuration_setting: [
    { id: 0, application_name: "Http0", protocol: "Http", port: 0 }
  ],
  externalservices_setting: [
    { id: 0, application_name: "Tcp0", protocol: "Tcp", port: 0 }
  ]
};

// represent the text shown on page to user.
export const FORM_LABELS = {
  name: "Name",
  description: "Description",
  version: "Version",
  category: "Cateory",
  display_url: "Display Url",
  image_url: "Image Url",
  user_name: "User Name",
  password: "Password",
  no_of_vcpu: "Number of Vcpu",
  ram: "Ram",
  disk: "Disk",
  cpu_type: "CPU Type",
  dev_type: "DEV Type",
  interface_type: "Interface Type",
  management_interface: "Management Interface",
  additional_interface: "Additional Interface",
  max_nodes: "Max Nodes",
  post_inst_script: "Post Inst Script",
  configuration_setting: "Configuration Settings",
  externalservices_setting: "External Services Settings"
};

// export const name, description, version, category, display_url,
//   image_url, user_name, password, no_of_vcpu, ram, disk, cpu_type, dev_type,
//   interface_type, management_interface, additional_interface, max_nodes, post_inst_script,
//   configuration_setting, externalservices_setting;

export const CATEGORY = ["Custom","Generic Shapes", "Cisco Networking", "Desktops","viptela1","Linux Networking","Viptela Objects","Transports","Traffic Generators"];
export const NO_OF_VCPU = [1, 2, 4, 8, 16, 32];
export const RAM = [1, 2, 4, 8, 16, 32];
export const DISK = [10, 20, 40, 50, 80, 100];
export const CPU_TYPE = ["Pass Through", "Model"];
export const DEV_TYPE = ["virtIO", "virtIO2", "virtIO3"];
export const INTERFACE_TYPE = ["e1000", "VIRTIO"];
export const ADDITIONAL_INTERFACE = ["1", "2", "3"];
