import React from "react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import { FormControl, InputLabel, OutlinedInput, InputAdornment, makeStyles, Icon, Button } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import Axios from "axios";
import Issue from "@material-ui/icons/Edit";
import CardFooter from "components/Card/CardFooter";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Back from "@material-ui/icons/ArrowBackRounded"
import { Api } from "config/api";

const useStyles = makeStyles(styles);

export default function UserInfo(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardGrow");
  const [ps1, setPass1] = React.useState(null);
  const [ps2, setPass2] = React.useState(null);
  setTimeout(function() {
    setCardAnimation("");
  }, 1);
    const classes = useStyles();
    const handleSubmit = () =>{
        // update password API
     
          const header = {
           "Content-type":"application/json",
         }
         Axios.post(Api.resetPassword, {
             "email": props.email,
             "username":props.username,
             "password":ps2,
             
         }, header)
           .then((data)=>{
             console.log(data)
             
           }).catch(err =>{
             console.log(err);
           })
         
     
       
       }
       const handleChangeIssue = () =>{
        console.log("ok")
    }
       const handleInputPassword1 = e => {setPass1(e.target.value)}
       const handleInputPassword2 = e => {setPass2(e.target.value)}
    const handleCallback = () =>{
        props.callback(false);
    } 
       
    return(
        <Card >
        <CardHeader>
            <div ><p style={{color:"#067193", fontWeight:"bolder"}}><b>Update User Informantion</b></p></div>
          </CardHeader>
        
          <CardBody >
          <GridContainer>
                            <GridItem xs={12}>
                            <FormControl  fullWidth className={classes.margin}  variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-amount">First Name</InputLabel>
                            <OutlinedInput
                                
                                onChange={handleChangeIssue}
                                endAdornment={<InputAdornment position="end">
                                <Issue className={classes.inputAdornmentIcon} />
                            </InputAdornment>}
                                labelWidth={60}
                            />
                            </FormControl>
                            <FormControl  fullWidth className={classes.margin}  variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-amount">Last Name</InputLabel>
                            <OutlinedInput
                            
                                onChange={handleChangeIssue}
                                endAdornment={<InputAdornment position="end">
                                <Issue className={classes.inputAdornmentIcon} />
                            </InputAdornment>}
                                labelWidth={60}
                            />
                            </FormControl>
                            <FormControl  fullWidth className={classes.margin}  variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-amount">Organisation</InputLabel>
                            <OutlinedInput
                            
                                onChange={handleChangeIssue}
                                endAdornment={<InputAdornment position="end">
                                <Issue className={classes.inputAdornmentIcon} />
                            </InputAdornment>}
                                labelWidth={60}
                            />
                            </FormControl>
                            
                            
                            <FormControl  fullWidth className={classes.margin}  variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-amount">Country</InputLabel>
                            <OutlinedInput
                            
                                onChange={handleChangeIssue}
                                endAdornment={<InputAdornment position="end">
                                <Issue className={classes.inputAdornmentIcon} />
                            </InputAdornment>}
                                labelWidth={60}
                            />
                            </FormControl>
                            <FormControl  fullWidth className={classes.margin}  variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-amount">Phone</InputLabel>
                            <OutlinedInput
                            
                                onChange={handleChangeIssue}
                                endAdornment={<InputAdornment position="end">
                                <Issue className={classes.inputAdornmentIcon} />
                            </InputAdornment>}
                                labelWidth={60}
                            />
                            </FormControl>
                            
                            
                            </GridItem>
                          </GridContainer>
                        
          
          </CardBody>
          
          <CardFooter className={classes.justifyContentCenter}>
          <GridContainer>
              <GridItem xs={4}>
                <Button onClick={handleCallback} simple size="sm" ><Back  className={classes.inputAdornmentIcon} /></Button>
              </GridItem>
              <GridItem xs={4}>
              <Button onClick={() => handleSubmit()} style={{background:"#067193", color:"white"}} simple size="md" >
              update
            </Button>
              </GridItem>
            </GridContainer>
           
            
          </CardFooter>
          
        
        </Card>
    )
}