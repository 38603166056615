import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/EventNoteTwoTone";

import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import { dataTable } from "variables/general.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Axios from "axios";
import { Api } from "config/api";
import { header } from "config/api";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const ContentStyle = {
  color:"#067193", fontSize:"17px", fontWeight:"bolder"
}

const useStyles = makeStyles(styles);

export default function ScheduleCluster() {

  const [data, setData] = React.useState();
  
  const handleDelete = (uuid) =>{
    console.log(uuid)
    Axios.post(Api.delScheduleCluster, {"uuid": uuid}, {headers:header}
    ).then(()=>{
      alert("Deleted")
    }).catch(err=>{
      console.log(err)
    })
  }
  React.useEffect(()=>{

    Axios.post(Api.getScheduleCluster,{name:"list"}, {headers:header})
      .then((dataTable)=>{

      
      console.log(dataTable.data.list)
      setData(
        dataTable.data.list.map((prop, key) => {
        
       return prop.deleted === false && {
          id: key,
          labname: <p style={ContentStyle}>{prop.template_name}</p>,
          labtype: <p style={ContentStyle}>{prop.resource_type}</p>,
          stl: <p style={ContentStyle}>{prop.schedule_start_time}</p>,
          actions: (                                                                                         
            // we've added some custom button actions
            <div className="actions-right">
  
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  handleDelete(prop.uuid)
                 
                }}
                color="danger"
                className="remove"
              >                                  
                <Close />
              </Button>
            </div>
          )
        };
      }))
    })
      .catch(err=>{
        console.log(err)
      })
  },[]);
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}> Scheduled Cluster list</h4>
          </CardHeader>
          <CardBody >
           
           <div style={{padding:"30px"}}> 
               <ReactTable
              data={data}
              filterable
              
              columns={[
               
                {
                  Header: "Lab Name",
                  accessor: "labname"
                },
                {
                    Header: "Lab type",
                    accessor: "labtype"
                },
                {
                  Header: "Scheduled Time (GMT)",
                  accessor: "stl"
                },
                
                {
                  Header: "Actions",
                  accessor: "actions",
                  sortable: false,
                  filterable: false
                }
              ]}
              defaultPageSize={5}
         
              showPaginationTop={true}
              showPaginationBottom={false}
              
              className="-striped -highlight"
            />
        </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
