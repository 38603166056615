import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from "@material-ui/icons/Store";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Language from "@material-ui/icons/Language";

import ReactMarkdown from 'react-markdown';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "variables/charts";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import priceImage1 from "assets/img/card-2.jpeg";
import priceImage2 from "assets/img/card-3.jpeg";
import priceImage3 from "assets/img/card-1.jpeg";
import Axios from "axios";
import { Api } from "config/api";
import Loader from "react-loader-spinner";
import { Paper } from "@material-ui/core";
import { header } from "config/api";
import auth from "auth/auth";

const fontStyle = {
  color:"#067193",
  fontSize:"15px",
  fontWeight:"bolder"
}

const fontStyle2 = {
  color:"#067193",
  fontSize:"17px",
  fontWeight:"bolder"
}

const buttonStyle = {
  fontSize:"15px",
  backgroundColor:"#067193",
  color:"#ffffff",
  fontWeight:"bolder"
}


const useStyles = makeStyles(styles);

export default function LaunchSales(props) {
  const classes = useStyles();
  const [sale, ViewSale] = React.useState([]);
  const [ty, SetTypes] = React.useState([]);
  const [cID, setCID] = React.useState(2);
  const [rule, setRule] = React.useState([]);
  const [loader, setLoader] = React.useState(true);



  React.useEffect(()=>{
    Axios.get(Api.viewSalesDemo, {headers:header})
    .then((data)=>{
        console.log(data.data)
      ViewSale(data.data)
      if(auth.permission[0].permissions === undefined){
        setRule(auth.permission)
      }else{
        setRule(auth.permission[0].permissions)
      }
      
      setLoader(false)
      
    }).catch(err=>{
      console.log(err);
    })
    Axios.get(Api.adminLabTypes, {headers:header})
    .then((data)=>{
        console.log(data.data)
      SetTypes(data.data)
    }).catch(err=>{
      console.log(err);
    })

    },[]);

    const handleLab = (event)=>{
      setCID(event)
      }
      const handleChange = ()=>{
        props.handleBack()
    }
    const isAllowed = (name) =>{
      let allow = false
      
      if(rule === "admin"){
        allow = true
      }else{
        rule.map((x, index)=>{
            if(name === x.resource_name){
                allow = true
            }
        })

    }

      return allow
  }

    const CategoryLab = ty.map((x, index)=>
    x.type==="salesdemo"&&
    <GridItem>
         <div style={{padding:"5px"}}>
    <Button  onClick={()=>handleLab(x.id)} style={{backgroundColor:cID ===x.id?"#067193":"#ffffff", color:cID===x.id?"#ffffff":"#067193", fontWeight:"bolder"}}>
        <b>{x.name}</b>
    </Button>
    </div>
    </GridItem>
)


  return (
    <div>
    {loader?
            <GridContainer justify="center">
                <GridItem md={6}>
                    <div style={{marginTop:"20%", marginLeft:"40%"}}>
                    <Loader 
                    type="Rings"
                    color="#067193"
                    height={100}
                    width={100}
                    
                    /></div>
                </GridItem>
            </GridContainer>:
            <div>
            <GridContainer >
                <GridItem xs={1}>
                <Button variant="outlined"  onClick={handleChange} style={{color:"#067193",backgroundColor:"#f6f6f6", fontWeight:"bolder", marginTop:"20px"}}><Icon>arrow_back</Icon> Back</Button>
                </GridItem>
                <GridItem xs={2}>
                </GridItem>
                <GridItem xs={1}>
                
                    <p style={{color:"#067193", fontWeight:"bolder", marginTop:"30px"}}><b>Select Category</b></p>
                </GridItem>
                <GridItem >
                <Paper style={{padding:"5px"}} elevation={0} square>
                <GridContainer>
                    
                    {CategoryLab}
                
                </GridContainer>
                
                </Paper>
                </GridItem>
            </GridContainer>
            <hr />
            <GridContainer>
                
                    {sale.map((y, index2)=>
                        y.admin_lab_types_id === cID&&isAllowed(y.identifier)&&
                        
                        <GridItem xs={12} sm={12} md={12}>
                            <div style={{width:"86%", marginLeft:"50px", marginTop:"15px"}}>
                            <Paper elevation={1}>
                                <GridContainer>
                                    <GridItem xs={3}>
                                    <div style={{paddingLeft:"10px"}}>
                                    <div product className={classes.cardHover}>
                                            <CardHeader image className={classes.cardHeaderHover}>
                                                <img src={y.image===""?"https://s3-us-west-2.amazonaws.com/sdcloud-imgs/general/LearningLab2.png":y.image} alt="..." style={{height:"200px"}}/>
                                            </CardHeader>
                                    </div>
                                    </div>
                                    </GridItem>
                                    
                                    <GridItem xs={9}>
                                        <GridContainer>
                                            <GridItem xs={6}>
                                           
                                            <div style={{padding:"2px",marginTop:"20px"}}>
                                            
                                            <img style={{width:"15px"}} src={"https://sdcloud-beta.criterionnetworks.com/criterion/img/icons/learnings/certification.png"} alt="..."/><b style={{color:"#067193",fontSize:"16px",fontWeight:"bolder"}}> {" "} {y.short_title}</b> 
                                            
                                            </div>
                                            
                                            <hr />
                                            
                                        
                                            </GridItem>
                                            <GridItem>
                                                <div style={{marginTop:"25px"}}>
                                                    <p style={fontStyle}>Run Time : <b style={fontStyle}>{y.lab_run_time} </b></p>
                                                </div>
                                            </GridItem>
                                        </GridContainer>
                                        <br />
                                        <GridContainer>
                                            <GridItem>
                                                <div style={{padding:"10px",fontSize:"14px",color:"#2a99bd",fontWeight:"bolder"}}>
                                                    <ReactMarkdown source={y.description} />
                                                </div>
                                            </GridItem>
                                        </GridContainer>
                                        
                                        <GridContainer>
                                        <hr />
                                        
                                        <GridItem>
                                            <div style={{padding:"10px"}}> 
                                        
                                            <Button style={buttonStyle} ><Icon  color="#ffffff">launch</Icon> &nbsp;Launch</Button></div>
                    
                                            </GridItem>
                                            <GridItem>
                                        
                                            <div style={{padding:"10px"}}><Button style={buttonStyle}><Icon color="#ffffff">schedule</Icon> &nbsp;Schedule</Button></div> 
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                </GridContainer>
                            </Paper>
                            <br />
                            <br />
                            </div>
                        </GridItem>
                    )}
            </GridContainer>
            </div>
        }
    </div>
  );
}