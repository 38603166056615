import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import { useHistory } from "react-router-dom";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import auth from "auth/auth";
import logo1 from "assets/img/login/step1.png";
import logo2 from "assets/img/login/step2.png";
import logo3 from "assets/img/login/step3.png";
import resetToken from "config/resetToken";
import Axios from "axios";
import { Checkbox, TextField, FormControl, InputLabel, OutlinedInput, CircularProgress } from "@material-ui/core";
import StyledCheckbox from "components/StyledCheckbox/StyledCheckbox";
import { Api } from "config/api";


const useStyles = makeStyles(styles);

export default function UpdatePass(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [open , setOpen] = React.useState(false)
  const [ps1, setPass1] = React.useState(null);
  const [ps2, setPass2] = React.useState(null);
  const [user, setUser] = React.useState({
    email:"",
    username:""
  });

  const u = window.location.search.split("=")[1]
  const history = useHistory();
  setTimeout(function() {
    setCardAnimation("");
  }, 700);

  React.useEffect(()=>{
    const v = resetToken.decyrpt(u);

    setUser({
      email:v.email,
      username:v.user
    })
  },[]);
  const handleUpdate = () =>{
   // update password API
   setOpen(true)
   
     const header = {
      "Content-type":"application/json",
    }
    Axios.post(Api.resetPassword, {
        "email": user.email,
        "username":user.username,
        "password":ps2
        
    }, header)
      .then((data)=>{
        
        setOpen(false)
        history.push("/auth/login-page")

      }).catch(err =>{
       
        console.log(err);
      })
    

  
  }

  const handleInputPassword1 = e => {setPass1(e.target.value)}
  const handleInputPassword2 = e => {setPass2(e.target.value)}

  

  const classes = useStyles();

  
  return (
    <div className={classes.container}>
      <GridContainer >
        {open?<GridItem xs={12} sm={6} md={6} style={{marginTop:"15%", marginLeft:"18%"}}><CircularProgress color="primary" /></GridItem>:
        <GridItem xs={12} sm={6} md={6} style={{marginTop:"10%"}}>
         <form>
            <Card login className={classes[cardAnimaton]}>
            <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="info"
              >
                <h4 className={classes.cardTitle}>Update Password</h4>
                
              </CardHeader>
             <div className={classes.cardBody}>
              <CardBody >
               
               
              <FormControl fullWidth className={classes.margin} variant="outlined" >
                  <InputLabel htmlFor="outlined-adornment-amount">Password</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    type="password"
                    onChange={handleInputPassword1}
                    endAdornment={<InputAdornment position="end">
                    <Icon className={classes.inputAdornmentIcon}>
                      lock_outline
                    </Icon>
                  </InputAdornment>}
                    labelWidth={60}
                  />
                </FormControl>
         
                <FormControl fullWidth className={classes.margin} variant="outlined" >
                  <InputLabel htmlFor="outlined-adornment-amount">Confirm Password</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    type="password"
                    onChange={handleInputPassword2}
                    endAdornment={<InputAdornment position="end">
                    <Icon className={classes.inputAdornmentIcon}>
                      lock_outline
                    </Icon>
                  </InputAdornment>}
                    labelWidth={60}
                  />
                </FormControl>
                
              
              
              
              </CardBody>
              
              <CardFooter className={classes.justifyContentCenter}>
              {ps1 === ps2&&<Button onClick={() => handleUpdate()} style={{background:"#067193"}} simple size="md" >submit</Button>}  
                  
                
              </CardFooter>
              
              </div>
            </Card>
          </form>
        </GridItem>}
      
      </GridContainer>
    </div>
  );
}
