import React from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Axios from "axios";
import { Api } from "config/api";
import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";
import Snackbar from "components/Snackbar/Snackbar";
import AddAlert from "@material-ui/icons/AddAlert";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";
import styler from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { Button, Icon, Paper, Typography } from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import parse from 'html-react-parser';
import ReactMarkdown from 'react-markdown';
import htmlParser from "react-markdown/plugins/html-parser";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter";
import NavPills from "components/NavPills/NavPills";
import Loader from "react-loader-spinner";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import { header } from "config/api";

const fontStyle = {
    color:"#067193",
    fontSize:"15px",
    fontWeight:"bolder"
}

const fontStyle2 = {
    color:"#067193",
    fontSize:"15px",
    fontWeight:"bolder",
    marginTop:"14px"
}

const useStyler = makeStyles(styler);

export default function NewSalesDemo(props) {
    const newClass = useStyler();

    const [detail, setDetail] = React.useState([]);
    const [alert, setAlert] = React.useState(null);
    const [tr, setTR] = React.useState(true);
    const [ip, setIP] = React.useState([]);
    const [value, setValue]  =  React.useState([]);
    const [top, setTopo]  =  React.useState([]);
    const [use, setUseCase]  =  React.useState([]);
    const [cheat, setCheat]  =  React.useState([]);
    const [sol, setSol]  =  React.useState([]);
    const [loader, setLoader] = React.useState(true);
    const [sid, setID] = React.useState(1);
    const [pid, setPID] = React.useState(0);
    const [collapse, setCollapse] = React.useState(false);
    const [fold, setFold]  =  React.useState({
        "side":2,
        "main":10,
        "sideCollapse":1,
        "mainCollapse":11
    });
  
    const u = window.location.search.split("=")[1]
  
 

    const handleCollapse =()=>{setCollapse(!collapse)}
  
    React.useEffect(()=>{
  
        Axios.get(Api.viewPoCLibraryDetail+u+"/labstatus/", {headers:header})
        .then((value)=>{
        console.log(value.data);
        setDetail([value.data]);
        })
        .catch((err)=>{
        console.log(err)
        })

        Axios.get(Api.viewPoCLibraryDetail+u+"/labdetail/", {headers:header})
        .then((value)=>{
          console.log(value.data);
          setIP([value.data]);
        })
        .catch((err)=>{
          console.log(err)
        })
  
        Axios.get(Api.viewSalesDemo, {"Content-Type":"application/json"})
        .then((data)=>{
            setValue(data.data);
            
        }).catch((err)=>{
            console.log(err);
        })
        
        Axios.get(Api.viewSalesDemoIntro, {"Content-Type":"application/json"})
        .then((data)=>{
            console.log(data.data);
            setTopo(data.data);
        }).catch((err)=>{
            console.log(err);
        })

        Axios.get(Api.viewSalesDemoUseCase, {"Content-Type":"application/json"})
        .then((data)=>{
            console.log(data.data);
            setUseCase(data.data);
        }).catch((err)=>{
            console.log(err);
        })
        Axios.get(Api.viewSalesDemoCheetSheet, {"Content-Type":"application/json"})
        .then((data)=>{
            console.log(data.data);
            setCheat(data.data);
            setLoader(false)
        }).catch((err)=>{
            console.log(err);
        })
         
        
    },[])

        const handleTerminate = (x, y)=>{
            inputConfirmAlertNext()
        }

        const inputAlert = (x,y) => {
       
        setAlert(
        
          <SweetAlert
            
            showCancel
            style={{ display: "block", marginTop: "-100px" }}
            title="Are You Sure ? "
            onConfirm={() => {
              handleTerminate( x, y);
              
            }}
            onCancel={() => hideAlert()}
            confirmBtnCssClass={newClass.button + " " + newClass.info}
            cancelBtnCssClass={newClass.button}
          />
        );
      };
      const inputConfirmAlertNext = () => {
        setAlert();
        setTimeout(() => {
          setAlert(
            
            <Snackbar
                place="tc"
                color="success"
                icon={AddAlert}
                message={`Terminated Successfully`}
                open={tr}
                closeNotification={()=>hideAlert()}
                close
            />
          );
        }, 200);
      };
      const hideAlert = () => {
        setAlert(null);
      };


    const handleMenu =(event)=>{
        setID(event)
    }

    const handleExercise =(event)=>{
        ComputeEndPanel(event.solution)
        setPID(event.exercise_position)
    }

    const handlePageNext = ()=>{
        setID(sid+1)
    }
    const handlePagePrev = ()=>{
        setID(sid-1)
    }

    const ComputeEndPanel = (event)=>{
        let panel = ""
        panel = (event).split('%%endpanel%%')
        setSol(panel)   
    }
      
    function Image(props) {
        return <img {...props} style={{maxWidth: '100%'}} />
    }

    function Head(props) {
        return <div style={{padding:"10px"}}><Paper variant="outlined" square elevation={2} style={{backgroundColor:"#0da1d0", padding:"10px", color:"#ffffff"}}><Typography variant={`h${props.level}`} {...props} /></Paper></div>
    }
  

    const IPAddress = ip.map((x, index)=>{
        let p="0.0.0.0"
        for (p in x.ipdetails){
  
          console.log(x.ipdetails[p]);
  
          return (x.ipdetails[p]).toString()
        }
    })

    const TimeDiff = detail.map((x, index)=>{
        let time = new Date(x.expiry_date)
    
        let ms = moment(time).diff(moment(Date()));
        let d = moment.duration(ms);
        let hrs = Math.floor(d.asHours())+" Hours   " + moment.utc(ms).format("mm")+" Minutes";
       
    
        return hrs
      })
    
    

    const Intro = detail.map((x, index)=>
    value.map((y, index2)=>
        x.labname === y.identifier&&top.map((z, index3)=>y.id === z.admin_sales_demos_id&&
        <div>
        <Card>
            <CardHeader color="info">
                <p style={{fontWeight:"bolder", fontSize:"20px"}}><b>Demo Introduction</b></p>
            </CardHeader>
            <CardBody>
            <div style={{color:"#067193", marginLeft:"20px"}}>
                <ReactMarkdown source={z.content}/>
            </div>
            </CardBody>
            
            <CardFooter>
            <Divider />
                <Button onClick={handlePageNext} style={{color:"#067193"}}>Next <Icon>arrow_forward_ios</Icon></Button>
            </CardFooter>
            
        </Card>
        
        </div>
    )))



    const Notes = detail.map((x, index)=>
    value.map((y, index2)=>
        x.labname === y.identifier&&
        <div>
        <GridContainer>
            <GridItem xs={12}>
            <Card>
            <CardHeader color="info">
                <p style={{fontWeight:"bolder",fontSize:"20px"}}><b>Demo Notes</b></p>
            </CardHeader>
            <CardBody>
            <div style={{color:"#067193", marginLeft:"20px"}}>
                {cheat.map((z, index3)=>y.id === z.admin_sales_demos_id&&<Button onClick={()=>handleExercise(z)}>{z.title}</Button>)}
            </div>
            {cheat.map((z, index3)=>pid === z.exercise_position&&y.id === z.admin_sales_demos_id&&
             
           
             <div style={{color:"#067193",padding:"15px", marginLeft:"20px"}}>
                 <ReactMarkdown source={z.intro_list} />
             </div>
           
             )}
            </CardBody>
            <CardFooter>
                <Divider />
                <GridContainer justify="center">
                    <GridItem xs={3}>
                    <Button onClick={handlePagePrev} style={{color:"#067193"}}><Icon>arrow_back_ios</Icon> Previous</Button>
                    </GridItem>
                    <GridItem xs={3}>
                    <Button onClick={handlePageNext} style={{color:"#067193"}}>Next <Icon>arrow_forward_ios</Icon></Button>
                    </GridItem>
                </GridContainer>
            </CardFooter>
       
            </Card> 
            </GridItem>

        </GridContainer> 
        <GridContainer>
            <GridItem xs={12} >
                {cheat.map((z, index3)=>pid === z.exercise_position&&y.id === z.admin_sales_demos_id&&
                        sol.map((a, index5)=>
                        <div >
                            {index5 < sol.length - 1&&
                            <Paper elevation={2} style={{color:"#067193", fontWeight:"bolder", padding:"15px"}}>
                             
                                <ReactMarkdown source={a.split('%%beginpanel%%')[1]} 
                                renderers={{image:Image, heading:Head}}
                                /> 
                            </Paper>}
                            <br />
                        </div>
                    ))}                
            </GridItem>
        </GridContainer>    
        </div>
    ))

    const UseCase = detail.map((x, index)=>
    value.map((y, index2)=>
        x.labname === y.identifier&&use.map((z, index3)=>(y.id).toString() === z.admin_sales_demos_id&&
       <Card>
           <CardHeader color="info">
                <p style={{fontWeight:"bolder",fontSize:"20px"}}><b>Demo Use Cases</b></p>
            </CardHeader>
            <CardBody>
            <div style={{color:"#067193", marginLeft:"20px"}}>
            
            <ReactMarkdown source={((z.content).split("%%beginbtn%%")[0]).split("%%beginbtn%%")[0]}/>
            <Button href={"http://"+IPAddress} target="blank" style={{backgroundColor:"#067193", color:"#ffffff", fontWeight:"bolder"}}><b>Launch</b><Icon>launch</Icon></Button>
            </div>
            </CardBody>
        

            <CardFooter>
                <Divider />
                <GridContainer justify="center">
                    <GridItem xs={3}>
                    <Button onClick={handlePagePrev} style={{color:"#067193"}}><Icon>arrow_back_ios</Icon> Previous</Button>
                    </GridItem>
                    <GridItem xs={3}>
                    <Button onClick={handlePageNext} style={{ color:"#067193"}}>Next <Icon>arrow_forward_ios</Icon></Button>
                    </GridItem>
                </GridContainer>
            </CardFooter>
        </Card>
      
    )))

    const Summary = detail.map((x, index)=>
    value.map((y, index2)=>
        x.labname === y.identifier&&top.map((z, index3)=>y.id === z.admin_sales_demos_id&&
        <div style={{color:"#067193", marginLeft:"20px"}}>
        <Card>
            <CardHeader color="info">
                <p style={{fontWeight:"bolder",fontSize:"20px"}}><b>Demo Summary</b></p>
            </CardHeader>
            <CardBody>
                defvgb
            </CardBody>
            <CardFooter>
                <Divider />
                <GridContainer justify="center">
                    <GridItem xs={3}>
                    <Button onClick={handlePagePrev} style={{ color:"#067193"}}><Icon>arrow_back_ios</Icon> Previous</Button>
                    </GridItem>
                    <GridItem xs={3}>
                    <Button onClick={handlePageNext} style={{color:"#067193"}}>Next <Icon>arrow_forward_ios</Icon></Button>
                    </GridItem>
                </GridContainer>
            </CardFooter>
            
        </Card>
        </div>
    )))

    const AccessDev = detail.map((x, index)=>
    value.map((y, index2)=>
        x.labname === y.identifier&&
        <Card>
            <CardHeader color="info">
                <p style={{fontWeight:"bolder",fontSize:"20px"}}><b>Access Devices</b></p>
            </CardHeader>
            <CardBody>
            
            <NavPills
                color="warning"
                tabs={[
                  {
                    tabButton: "Topology",
                    tabContent: (
                     
                      
                          <div style={{color:"#067193", marginLeft:"20px", width:"100%"}}>

                            {parse((y.access_details).split("custom-underlay1:80").join(IPAddress))}
                            </div>
                       
                    
                    )
                  },
                  {
                    tabButton: "IP Details",
                    tabContent: (
                      <span>
                          {detail.map((x, index)=>value.map((y, index2)=>
                          x.labname === y.identifier&&top.map((z, index3)=>y.id === z.admin_sales_demos_id&& 
                         <div> <ReactMarkdown source={(z.ip_details_access).split("custom-underlay1").join(IPAddress)} renderers={{image:Image}} /></div>)))}
                        
                        
                      </span>
                    )
                  }]}
              />
            
            </CardBody>
        

        <CardFooter>
                <Divider />
                <GridContainer >
                    <GridItem xs={3}>
                    <Button onClick={handlePagePrev} style={{color:"#067193"}}><Icon>arrow_back_ios</Icon> Previous</Button>
                    </GridItem>
                    
                </GridContainer>
            </CardFooter>
        </Card>
    ))
     

    return(
        <div>
        <GridContainer>
            <GridItem xs={3}>
            
            <div style={{backgroundColor:"#fcfcfc",padding:"14px", marginTop:"14px"}}>
                <GridContainer>
                    <GridItem xs={4}>
                        <p style={fontStyle}>Demo Name:</p>
                    </GridItem>
                    <GridItem xs={7}>
                        <p style={fontStyle}>{detail.map((x,index)=><b key={index}>{x.learning_name}</b>)} </p>
                    </GridItem>
                </GridContainer>
            
            </div>
            </GridItem>
            <GridItem xs={8}>

            <div style={{backgroundColor:"#fcfcfc", marginTop:"14px"}}>
                <GridContainer justify="center">
                    
                    <GridItem xs={4}>
                    <div style={{ marginTop:"16px", paddingLeft:"10px"}}>
                      {detail.map((x, index)=>
                            <CustomLinearProgress
                            variant="determinate"
                            color="info"
                            value={parseInt(x.percentage)}

                            />
                        )}
                    </div>
                    </GridItem>
                    <GridItem xs={2}>
                      <div style={{padding:"8px", fontWeight:"bolder"}}>{detail.map((x,index)=><Button style={{color:"white",backgroundColor:"#0da1d0" ,fontSize:"12px"}}><Icon style={{color:"#ffffff"}} fontSize="small">{x.status==="ready"?"done":"query_builder"}</Icon>&nbsp;&nbsp;{x.status}</Button>)}</div>
                    
                    </GridItem>
                    <GridItem xs={1}></GridItem>
                    
                    <GridItem xs={4}>
                   
                  <div style={{padding:"8px"}}>  <p  style={fontStyle }>Time Left :  <Button style={{color:"white",backgroundColor:"#0da1d0" ,fontSize:"12px"}}>{TimeDiff}</Button></p></div>
                    </GridItem>
                </GridContainer>
         
            </div>
            </GridItem>
        </GridContainer>
        <br />
        {loader?
            <GridContainer justify="center">
                <GridItem md={6}>
                    <div style={{marginTop:"20%", marginLeft:"40%"}}>
                    <Loader 
                    type="Rings"
                    color="#067193"
                    height={100}
                    width={100}
                    
                    /></div>
                </GridItem>
            </GridContainer>:
        <GridContainer>
            <GridItem xs={collapse?fold.sideCollapse:fold.side}>
            <div style={{backgroundColor:"#f6f6f6", marginTop:"26px"}}>
                <GridContainer >
                    <GridItem xs={3}>
                    <Button onClick={handleCollapse} style={{backgroundColor:"#0da1d0"}}><Icon style={{color:"#ffffff"}}>{collapse?"menu_open":"menu"}</Icon></Button>
                    </GridItem>
                    <GridItem xs={6}>
                    {collapse === false&&<p style={{color:"#067193",marginTop:"10px", fontWeight:"bolder"}}>Sales Demo Menu</p>}
                    </GridItem>
                </GridContainer>
            </div>
            {collapse === false &&
            <div style={{marginTop:"10px"}}>
               
                    <Paper elevation={sid === 1?1:2} onClick={()=>handleMenu(1)} style={{padding:"10px",marginTop:"10px",color:sid===1?"#ffffff":"#067193", backgroundColor:sid===1?"#067193":"#ffffff"}}>
                        {detail.map((x,index)=>
                         value.map((y, index2)=>x.labname === y.identifier&&
                           <GridContainer justify="center">
                               <GridItem xs={2}>
                                 <Icon fontSize="default">apps</Icon> 
                               </GridItem>
                               <GridItem xs={9}>
                                 <b key={index} style={{fontWeight:"bolder"}}> Demo Introduction</b>
                               </GridItem>
                            </GridContainer>
                            
                        ))}
                
                    </Paper>
                

                <Paper elevation={sid === 2?1:2} onClick={()=>handleMenu(2)} style={{padding:"10px",marginTop:"10px",color:sid===2?"#ffffff":"#067193", backgroundColor:sid===2?"#067193":"#ffffff"}}>
                  
                        {detail.map((x,index)=>
                         value.map((y, index2)=>x.labname === y.identifier&&
                         <GridContainer justify="center">
                            <GridItem xs={2}>
                                <Icon fontSize="default">note</Icon> 
                            </GridItem>
                            <GridItem xs={9}>
                                <b key={index} style={{fontWeight:"bolder"}}> Demo Notes</b>
                            </GridItem>
                         </GridContainer>
                    
                        ))}
                
                </Paper>

                <Paper elevation={sid === 3?1:2} onClick={()=>handleMenu(3)} style={{padding:"10px",color:sid===3?"#ffffff":"#067193",marginTop:"10px", backgroundColor:sid===3?"#067193":"#ffffff"}}>
                    
                        {detail.map((x,index)=>
                         value.map((y, index2)=>x.labname === y.identifier&&
                         <GridContainer justify="center">
                            <GridItem xs={2}>
                                <Icon fontSize="default">layers</Icon> 
                            </GridItem>
                            <GridItem xs={9}>
                                <b key={index} style={{fontWeight:"bolder"}}> Demo Use Cases</b>
                            </GridItem>
                         </GridContainer>
                    
                        ))}
                 

                </Paper>
                <Paper elevation={sid === 4?1:2} onClick={()=>handleMenu(4)} style={{padding:"10px",color:sid===4?"#ffffff":"#067193",marginTop:"10px", backgroundColor:sid===4?"#067193":"#ffffff"}}>
                   
                        {detail.map((x,index)=>
                         value.map((y, index2)=>x.labname === y.identifier&&
                         <GridContainer justify="center">
                            <GridItem xs={2}>
                                <Icon fontSize="default">description</Icon> 
                            </GridItem>
                            <GridItem xs={9}>
                                <b key={index} style={{fontWeight:"bolder"}}> Demo Summary</b>
                            </GridItem>
                         </GridContainer>
                        ))}
                    

                </Paper>
                <Paper elevation={sid === 5?1:2} onClick={()=>handleMenu(5)} style={{padding:"10px",color:sid===5?"#ffffff":"#067193",marginTop:"10px", backgroundColor:sid===5?"#067193":"#ffffff"}}>
                   
                        {detail.map((x,index)=>
                         value.map((y, index2)=>x.labname === y.identifier&&
                         <GridContainer justify="center">
                            <GridItem xs={2}>
                                <Icon fontSize="default">lock_open</Icon> 
                            </GridItem>
                            <GridItem xs={9}>
                                <b key={index} style={{fontWeight:"bolder"}}> Access Devices</b>
                            </GridItem>
                         </GridContainer>
                        ))}
                    
                </Paper>
                <hr />
                <Paper elevation={1} onClick={()=>inputAlert("a", "b")} style={{padding:"10px",color:"#d62222de",marginTop:"10px", borderColor:"#ffffff"}}>
                        <GridContainer justify="center">
                            <GridItem xs={2}>
                                <Icon fontSize="small">clear</Icon> 
                            </GridItem>
                            <GridItem xs={9}>
                                 <b  style={{fontWeight:"bolder"}}>Terminate Demo</b>
                            </GridItem>
                        </GridContainer>
                   
                </Paper>
            </div>}
            </GridItem>
            {alert}
            <GridItem xs={collapse?fold.mainCollapse:fold.main}>
                {sid === 1?Intro:sid===2?Notes:sid === 3?UseCase:sid === 4?Summary:sid === 5&&AccessDev}
            </GridItem>
        </GridContainer>}
        </div>

    );
    
}