class SendMail  {

    send(cb, token) {
        // Send Grid
        console.log("send Mail")
        alert(token.email)
        return true
    }

}

export default new SendMail();