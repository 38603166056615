import React from "react";
import { Router, Route, useHistory, Redirect } from "react-router-dom";
import LoginPage from "views/Pages/LoginPage";

export default function UnAuthenticatedRoute({ component: C, appProps, ...rest }, props){
  const [l, log] = React.useState(false);
  const history = useHistory();
  const callBack = (d) =>{
    log(d)

  }
  return(
 <Route
    {...rest}
    render={props =>
      !appProps.isAuthenticated 
        ?  <C {...props} {...appProps} />
        : <Redirect from="/" to="/admin/dashboard" />}
  />); }