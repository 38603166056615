import React from 'react';
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Card, Button, Input, Grid, FormControl, makeStyles, InputLabel, OutlinedInput, InputAdornment } from "@material-ui/core";
import CardHeader from "components/Card/CardHeader";
import Axios from 'axios';
import { Api } from 'config/api';
import CardBody from 'components/Card/CardBody';
import parse from 'html-react-parser';
import Datetime from "react-datetime";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import CardFooter from 'components/Card/CardFooter';
import { header } from 'config/api';
import auth from 'auth/auth';


const useStyles = makeStyles(styles);
export default function LaunchPoC(props) {
    const [value, setValue]  =  React.useState([]);
    const [ind, setIndex] = React.useState();
    const [sand, setSand] = React.useState("");
    const [rule, setRule] = React.useState([]);


    React.useEffect(()=>{
        Axios.get(Api.viewPoCLibrary, {"Content-Type":"application/json"})
            .then((data)=>{
                setValue(data.data);
                if(auth.permission[0].permissions === undefined){
                    setRule(auth.permission)
                  }else{
                    setRule(auth.permission[0].permissions)
                  }
                  
            }).catch((err)=>{
                console.log(err);
            })
    },[]);
    const handleSand = (data) => {setIndex(data);}
    const isAllowed = (name) =>{
        let allow = false
        if(rule === "admin"){
            allow = true
          }else{
            rule.map((x, index)=>{
                if(name === x.resource_name){
                    allow = true
                }
            })
    
        }

        return allow
    }


    const title = value.map((x, index)=>isAllowed(x.identity+"_basic_simple_cloud")&&
        <div>
            <Card key={index} >
                <CardHeader>
                    <Button onClick={()=>handleSand(x.id)} style={{color:"#067193"}}>{x.title}</Button>
                </CardHeader>
            </Card>
            <br />
        </div>
    );
    const handleInputSand = (val) => {
        setSand(val)
    }
    const handleProvision = (x) =>{
        value.map((x, index)=>{
            if(x.id === ind){
                const data = {
                    "clustername":"newTest",
                    "template_name":x.identity+"_basic_simple_cloud",
                    "os_version":"custom",
                    "sdn_type":x.identity,
                    "ha_type":"simple",
                    "cloud_provider":"gcp"  
                }
                Axios.post(Api.provisonCluster, data, {headers:header})
                .then((data)=>{
                    console.log(data);
                   
                }).catch((err)=>{
                    console.log(err);
                })
            }
        })
       

    }
    const handleSchedule = () =>{
        console.log("ok");
    }
    const classes = useStyles();
    return(
      
        <GridContainer>
            <GridItem xs={2} >
            <Card style={{backgroundColor:"#067193", color:"white"}}>
                <h5 style={{marginLeft:"27%"}}><b>Select Library</b></h5>
            </Card><hr />
               {title}
            </GridItem>

            <GridItem xs={10} >
                <Card >
                    <CardHeader style={{backgroundColor:"#0da1d0", color:"#ffffff"}}>
                        {value.map((x, index)=>x.id === ind &&<h4><b>{x.title}</b></h4>)}
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                                                       
                            <GridItem xs={5}>
                            <FormControl  fullWidth className={classes.margin}  variant="outlined">
                                 <InputLabel htmlFor="outlined-adornment-amount">Enter Sandbox Name</InputLabel>
                                <OutlinedInput
                                
                                    onChange={handleInputSand}
                                    
                                    labelWidth={50}
                                />
                            </FormControl>
                            </GridItem>
                            <GridItem xs={2}>
                             <div >   <Datetime inputProps={{ placeholder: "Datetime Picker Here" }}  /> </div>
                            </GridItem>

                            <GridItem xs={2}>
                            <Button onClick={handleSchedule}>Schedule Sandbox</Button> 
                            </GridItem>
                        </GridContainer><br />
                        <GridContainer>
                        <GridItem xs={7}>
                          
                            </GridItem>
                        <GridItem xs={2}>
                            <Button onClick={handleProvision}>Provision Sandbox</Button>
                            </GridItem>
                        </GridContainer>
                        
                     
                    </CardBody><hr />
                    <CardFooter>
                     {value.map((x, index)=>x.id === ind &&<p style={{color:"#067193", marginTop:"3%"}}>{parse(x.description)}</p>)}
                    </CardFooter>
                </Card>
                <hr />
                <Card >
                    <CardBody>
                    <GridContainer>
                    <GridItem xs={7}>
                    {value.map((x, index)=>x.id === ind &&<img src={x.diagram_img} style={{height:'600px', width:"95%"}}/>)}
                    </GridItem>
                    <GridItem xs={5}>
                    {value.map((x, index)=>x.id === ind &&<p style={{color:"#067193"}}>{parse(x.description)}</p>)}
                    </GridItem>
                    </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
        
    );
}