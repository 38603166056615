import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import style from "assets/jss/material-dashboard-pro-react/views/myProfile.js"
import { makeStyles, FormControl, InputLabel, OutlinedInput, InputAdornment, Button, Paper, Icon, Divider } from "@material-ui/core";
import Issue from "@material-ui/icons/Edit";
import Terminate from "@material-ui/icons/DeleteForeverSharp";
import Forward from "@material-ui/icons/ArrowForwardSharp"
import ProfilePass from "./ProfilePass";
import UpdateSec from "./UpdateSec";
import UserInfo from "./UserInfo";
import mergeState from "config/mergeState";
import Step1 from "views/Forms/WizardSteps/Step1";
import ViewProfile from "./ViewProfile";
import Axios from "axios";
import { Api } from "config/api";
import resetToken from "config/resetToken";
import CardHeader from "components/Card/CardHeader";
import { header } from "config/api";

const useStyle = makeStyles(style)

export default function MyProfile(props) {
   const [show, setShow] = React.useState(0);

    const [user, setUser] = React.useState([]);
    


    const handleChange = (x) =>{
        setShow(x)
    }

  
    React.useEffect(()=>{

        Axios.get(Api.getUserProfile, {headers:header})
        .then((data)=>{
         console.log(data.data);
         setUser([data.data])
        }).catch(err =>{
          console.log(err);
         
        })
    },[])
  
    const classes = useStyle();
    const handleChild = () =>{
        setShow({
            password:true,
            passEvent:false,
            info:true,
            infoEvent:false,
            security:true,
            secEvent:false,
        })
    }
    return(
        <div style={{marginTop:"2%"}}>
       <GridContainer > 
           <GridItem xs={8}>
               <Card className={classes.showCard}>
                   <CardHeader color="info">
                       <h4><b>My Profile</b></h4>
                   </CardHeader>
                   <CardBody>
                    <GridContainer>
                        
                        <GridItem xs={7}>
                              
                            <Paper elevation={1}>
                                {user.map((x, index)=>
                                <GridContainer>
                                    <GridItem xs={1}>

                                    </GridItem>
                                    <GridItem xs={2}>
                                        <div style={{color:"#067193",marginTop:"15%", fontWeight:"bolder"}}>
                                            <p>Username</p>
                                            <p>Email</p>
                                            <p>First Name</p>
                                            <p>Last Name</p>
                                            <p>Company</p>
                                            <p>Phone</p>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={1}>
                                        <div style={{color:"#067193",marginTop:"40%"}}>
                                            <Icon>arrow_right</Icon>
                                            <Icon>arrow_right</Icon>
                                            <Icon>arrow_right</Icon>
                                            <Icon>arrow_right</Icon>
                                            <Icon>arrow_right</Icon>
                                            <Icon>arrow_right</Icon>
                                        </div>

                                    </GridItem>
                                    <GridItem xs={6}>
                                        <div style={{color:"#067193",marginTop:"4%", fontWeight:"bolder"}}>
                                           <p><b>{x.username}</b></p>
                                           <p><b>{x.email}</b></p> 
                                           <p><b>{x.firstname}</b></p>
                                           <p><b>{x.lastname}</b></p>
                                           <p><b>{x.company}</b></p>
                                           <p><b>{x.phone}</b></p>
                                        </div>
                              
                                    </GridItem>
                                </GridContainer>
                                
                                )}
                            </Paper>
                        </GridItem>
                        <GridItem xs={5}>
                        <Button className={classes.NewCard} onClick={()=>handleChange(1)} variant="contained" >
                            change Password
                        </Button>
                        <Button className={classes.NewCard} onClick={()=>handleChange(2)} variant="contained">
                            Update Security Question
                        </Button>
                        <Button className={classes.NewCard} onClick={()=>handleChange(3)} variant="contained">
                            Update Info
                        </Button>
                        </GridItem>
                   
                    </GridContainer>
                   </CardBody>
               </Card>
           </GridItem>
           <GridItem xs={3}>
               <Card className={classes.showCard} >
                   <CardBody>
                       <div style={{marginLeft:"20%"}}>
                            <h5 style={{color:"#067193", fontWeight:"bolder"}}><b>Request Terminate Account</b></h5>
                                <p color="red"> * Account Will be Terminate by Admin </p>
                        </div>
                            <hr />
                        <div style={{marginLeft:"20%"}}>
                            <Button style={{color:"red"}} onClick={()=>handleChange(3)} variant="outlined" endIcon={<Terminate />}>Terminate Account</Button>
                        </div>
                   </CardBody>
               
               </Card>
           </GridItem>
        
       </GridContainer>
       
       <GridContainer>
           <GridItem xs={3}></GridItem>
           <GridItem xs={5}>
            {show === 1?<div className={classes.card}><ProfilePass callback={handleChild} dataProp={user} /></div>
            :show === 2?<div className={classes.card}><UpdateSec callback={handleChild} dataProp={user} /></div>
            :show === 3&&<div className={classes.card}><UserInfo callback={handleChild} dataProp={user} /></div>}
           </GridItem>
           <GridItem xs={2}></GridItem>
       </GridContainer>
       </div>
    );
}