import { useHistory } from "react-router-dom";

class Auth {
    constructor(){
        this.authenticated = false;
        this.permission = [];
    }

    login(cb) {
        this.authenticated = true;
        if(localStorage.getItem('user')){
           
            cb(localStorage.getItem('user'))
        }else{
           
            cb();
        }
       

      
    }

    perm(x) {
      this.permission = x;
      x()
    }

    logout(cb) {
        
        this.authenticated = false;
        localStorage.removeItem('user')
        useHistory().push("/auth/login-page")
        cb();
    }


    isAuthenticated() {
       
        return this.authenticated
    }
}

export default new Auth();