import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Person from "@material-ui/icons/PersonOutlineOutlined";
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic'


const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

export default function Drawer(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({

    right: false,
  });

  const toggleDrawer = (anchor, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = anchor => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
    //   onClick={toggleDrawer(anchor, false)}
     // onKeyDown={toggleDrawer(anchor, false)}
    >
     {props.component}
    </div>
  );

  

  return (
    <div >
      {[ props.where ].map(anchor => (
        <React.Fragment key={anchor} >
           {props.topic? 
          <Person
            onClick={toggleDrawer(anchor, true)}
        style={{color:"#067193", fontSize:"25px"}}
          
        />:<HeadsetMicIcon
       
            onClick={toggleDrawer(anchor, true)}
        style={{color:"#067193", fontSize:"25px"}}
          
        />}
         
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}