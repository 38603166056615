import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import style from "assets/jss/material-dashboard-pro-react/views/myProfile.js"
import { makeStyles, FormControl, InputLabel, OutlinedInput, InputAdornment, Select, TextField, Button, Paper, Icon } from "@material-ui/core";
import Issue from "@material-ui/icons/Edit"
import { SupportQues } from "views/Pages/resetHelper/question";
import ImageUpload from "components/CustomUpload/ImageUpload";
import sendMail from "config/sendMail";
import mergeState from "config/mergeState";

const supportStyle = {
    marginLeft:"25%",
    color:"#067193",
    fontWeight:"bolder"
}


const useStyle = makeStyles(style);

export default function Support(props) {

    const [inObj, setInput] = mergeState.useMergeState({
        email:"",
        issue:"",
        offering:"",
        detail:"",
        image:""
    })

   
    const handleChangeIssue = e =>{setInput({issue:e.target.value});console.log(inObj.issue)}
    const handleChangeEmail = e =>{setInput({email:e.target.value});console.log(inObj.email)}
    const handleChangeOffer = e =>{setInput({offering:e.target.value});console.log(inObj.offering)}
    const handleChangeDetail = e =>{setInput({detail:e.target.value});console.log(inObj.detail)}
    const handleChangeImage = e =>{setInput({image:e.target.value});console.log(inObj.image)}


    const classes = useStyle();
    const handleSubmit = () =>{
        sendMail.send(inObj.email, inObj)
    }
    return(
        <div style={{overflow:"hidden"}}>
       <GridContainer >
           <GridItem xs={2}>
             <Paper elevation={3} square style={{backgroundColor:"#067193", marginLeft:"10px"}}>
               <h3 style={{marginTop:"30%",marginLeft:"1%",padding:"10px",color:"#ffffff",fontWeight:"bolder"}}>Support Request <Icon style={{marginLeft:"10px"}}>headset_mic</Icon></h3>
             </Paper>
             <p style={supportStyle}><b>* Raise A New Issue</b></p>
             <p style={supportStyle}><b>* Elaborate as much as you can</b></p>
             <p style={supportStyle}><b>* You'll be Contacted via Email</b></p>
           </GridItem>
           <GridItem xs={9}>
               <Card className={classes.infoCard} style={{padding:"20px"}}>
                  
                   <CardBody>
                    <GridContainer>
                        
                        <GridItem xs={4}>
                        
                        <FormControl  fullWidth className={classes.margin}  variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-amount">Issue Title</InputLabel>
                            <OutlinedInput
                            
                              onChange={handleChangeIssue}
                              endAdornment={<InputAdornment position="end">
                              <Issue className={classes.inputAdornmentIcon} />
                            </InputAdornment>}
                              labelWidth={60}
                            />
                          </FormControl>
                          <FormControl  fullWidth className={classes.margin}  variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-amount">Email</InputLabel>
                            <OutlinedInput
                            
                              onChange={handleChangeEmail}
                              endAdornment={<InputAdornment position="end">
                              <Issue className={classes.inputAdornmentIcon} />
                            </InputAdornment>}
                              labelWidth={60}
                            />
                          </FormControl> 
                
                <FormControl fullWidth  className={classes.marginT} variant="outlined">
                    <InputLabel  htmlFor="outlined-age-native-simple">Select Offerings</InputLabel>
                        <Select
                        native
                        id="outlined-age-native-simple"
                       
                        onChange={handleChangeOffer}
                        
                     labelWidth={60}
                        
                        >
                            {SupportQues.map((x, index)=>
                                <option key={index} value={x} >{x}</option>
                            )}
                        
                     
                    </Select>
                 </FormControl>
                        </GridItem>
                        <GridItem xs={5}>
                          
                        <FormControl  fullWidth className={classes.margin}  variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-amount" />
                 
                  <TextField
                    id="outlined-multiline-static"
                    label="Issue In Details"
                    multiline
                    rows="10"
                  
                    variant="outlined"
                    onChange={handleChangeDetail}
                    endAdornment={<InputAdornment position="end">
                    <Issue className={classes.inputAdornmentIcon} />
                  </InputAdornment>}
                    labelWidth={60}
                  />
                </FormControl>
                        </GridItem>
                <GridItem xs={3}>
                        <FormControl  fullWidth className={classes.margin}  variant="outlined">
                        <ImageUpload
                            onChange={handleChangeImage}
                            addButtonProps={{
                            color: "info",
                            round: true
                            }}
                            changeButtonProps={{
                            color: "info",
                            round: true
                            }}
                            removeButtonProps={{
                            color: "info",
                            round: true
                            }}
                        />
                        </FormControl> 
                        </GridItem>
                    </GridContainer>
                    <hr />
                    <GridContainer justify="center">
                        <GridItem xs={3}>
                        
                        </GridItem>
                        <GridItem xs={4}>
                         
                            <Button onClick={handleSubmit} style={{backgroundColor:"#067193", color:"#ffffff"}} >send Issue  <Icon style={{marginLeft:"10px"}}>send</Icon></Button>
                        </GridItem>
                    </GridContainer>

                   </CardBody>
               </Card>
           </GridItem>
           <GridItem xs={1}>
           
           </GridItem>
       </GridContainer>
       </div>
    );
}