import React from 'react';
import { Router, Route, Switch, Redirect } from "react-router-dom";
import auth from './auth';
export default function AuthenticatedRoute({ component: C, appProps,...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          auth.isAuthenticated()
            ? <C {...props} {...appProps}/>
            : <Redirect
                  to={`/auth/login-page`}
              />}
      />
    );
  }