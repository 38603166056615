

const myProfile = {

  infoCard:{
      backgroundColor:"#fcfcfc",
      marginLeft:"30px"
    },
   showCard:{
      backgroundColor:"#ffffff",
    
    },
  headerCard:{
      backgroundColor:"#067193",
      color:"#ffffff"
    },

  margin:{
    marginBottom:"9px"
  },
  card:{
    marginTop:"-30px",
    height:"80%"
  },
  marginT:{
    marginTop:"9px"
  },
  textFeild:{
    overflow:'auto',
    maxHeight:"130px"
  },
  button:{
    marginLeft:"50%"
  },
  NewCard:{
      backgroundColor:"#067193",
      width:"55%",
      marginBottom:"10px",
      marginTop:"10px",
      color:"#fff",
      marginLeft:"30%"

    },
  NewCard2:{
    backgroundColor:"#067193",
    width:"90%"

  },
  NewText:{
    fontSize:"15px",
    color:"#ffffff",
    fontWeight:"bolder"
  },
  NewIcon:{
    color:"#ffffff"
  }
};

export default myProfile;
