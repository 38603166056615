import React from 'react';
import AuthenticatedRoute from "auth/AuthenticatedRoute";
import UnAuthenticatedRoute from "auth/UnAuthenticatedRoute";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import LoginPage from 'views/Pages/LoginPage';
import axios from 'axios';
import auth from 'auth/auth';
import Axios from 'axios';
import { Api } from 'config/api';

export default function App() {
    const [isAuthenticated, userHasAuthenticated] = React.useState(auth.isAuthenticated());
  
    React.useEffect(() => {
      onLoad();
    }, []);
  
    async function onLoad(d) {
      
      try {
          auth.login(()=>{
            let token = localStorage.getItem('user') || 'out'
            
            if(token !== "out"){
              userHasAuthenticated(true)
                //api refresh token
                let token = localStorage.getItem('user')
                Axios.get(Api.getUserProfile, {headers:{"Content-type":"application/json","Authorization":`token ${token}`}})
                .then((value)=>{
                  
                  value.data.privilege === "developer"?
                  Axios.get(Api.getProfile, {headers:{"Content-type":"application/json","Authorization":`token ${token}`}})
                    .then((value)=>{
                      auth.perm(()=>{auth.permission = value.data.profiles})
                      
                    })
                    .catch(()=>{
                      
                    }):auth.perm(()=>{auth.permission = "admin"})
                       
                  
                  })
                  .catch(()=>{
                  
                  })
            
                
            }else{
              
                userHasAuthenticated(false)
            }
            })
          
          // console.log(auth.permission)
       
      } catch (e) {
        alert(e);
      }
    }
  
    return (
      <div className="App">
       
        <Switch>
          <UnAuthenticatedRoute
            path="/auth"
            component={AuthLayout}
            appProps={{ isAuthenticated }}
          
          />
          <AuthenticatedRoute
            path="/admin"
            component={AdminLayout}
            appProps={{ isAuthenticated }}
          />
          <Redirect to="/auth/login-page" /> 
        
        
        </Switch>
      </div>
    );
  } 